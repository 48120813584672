.main_container {
    color: #FFFFFF;
    width: 100%;
    padding: 0 10px;
}

.heading {
    font-weight: 500;
    font-size: 30px;
    margin: 0;
}

.complain_header {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0;
    line-height: 1.5rem;
    justify-content: space-between;
    align-items: center;
}

.complain_search_div {
    position: relative;
    width: 20%;
}

.dealer_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 30px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 100%;
    min-width: 270px;
}

.dealer_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
}

.complain_header_right {
    display: flex;
    flex-direction: row;
}

.complain_Sort_By {
    padding-right: 30px;
}

.complain_sortBy_txt {
    font-size: 20px;
    padding-right: 12px;
}

.complain_sortBy_select {
    border: 1px solid #ffffff;
    height: 49px;
    width: 234px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 12px;
}

.table_heading_div {
    height: 50px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    border: 0.5px solid #3A3A3A;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    padding: 0 40px;
}

.table_heading {
    min-width: 16.66%;
    text-align: center;
}

.table_content_div {
    min-height: 50px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    color: #FFFFFF;
    border-radius: 6px;
    margin: 10px 0;
    padding: 0 40px;
    cursor: pointer;
}

.table_txt {
    min-width: 16.66%;
    text-align: center;
}

.table_txt_green {
    min-width: 16.66%;
    text-align: center;
    color: #29BB89;
}

.table_txt_yellow {
    min-width: 16.66%;
    text-align: center;
    color: #FFA32F;
}

.table_txt_red {
    min-width: 16.66%;
    text-align: center;
    color: red;
}

.theadWidth {
    display: flex;
    width: 100%;
}

.scrollTable {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 260px);
    min-height: calc(100vh - 260px);
}

.scrollTable::-webkit-scrollbar {
    display: none;
}

/* ------------------------------------------Complain-Modal---------------------------------------------- */

.modal {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    padding-top: 180px;
}

.modal_content {
    width: 90%;
    max-width: 1400px;
    background: #1E2022;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: auto;
    padding-top: 2px;
    color: #FFFFFF;
    padding-bottom: 4px;
}

.modal_content_light {
    width: 90%;
    max-width: 800px;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: auto;
    padding-top: 2px;
    border: 1px solid gray;
    color: #000000;
}

.modal_close_div {
    width: 100%;
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.5rem;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal_container {
    width: 80%;
    margin: auto;
}

.modal_heading {
    text-align: center;
    /* margin: 12px 0; */
}

.modal_sub_heading {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
}

.modal_description {
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    font-weight: 100;
    font-size: 19px;
    line-height: 25px;
    color: #FFFFFF;
    margin-top: 4px;
    padding: 10px 20px;
}

.complainDetails_div {
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
}

.complainDetails {
    font-weight: 100;
    font-size: 18px;
    line-height: 30px;
    color: #FFFFFF;
}

.close_btn {
    color: #aaaaaa;
    float: right;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.5rem;
    padding-right: 6px;
    cursor: pointer;
}

.customers {
    border-collapse: collapse;
    width: 100%;
    border: none;
    text-align: center;

    /* margin: 0 5px; */
}

.packing_left {
    text-align: left;
}

.complainDetails_right {
    width: 25%;
}

.right_content_div {
    display: flex;
    justify-content: space-between;
    font-weight: 100;
    font-size: 18px;
    line-height: 30px;
    color: #FFFFFF;
}

.right_txt {
    min-width: 90px;
    display: flex;
}

.complaintModal_btn {
    width: 400px;
    display: flex;
    justify-content: space-between;
    margin: 32px auto;
}

.cancelModal {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    padding-top: 300px;
}

.cancelModal_content {
    width: 90%;
    max-width: 500px;
    background: #1E2022;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: auto;
    padding-top: 2px;
    color: #FFFFFF;
    padding-bottom: 4px;
    text-align: center;
}

.checkIcon {
    margin-bottom: 20px;
}

.heading_resolve {
    font-weight: 500;
    font-size: 28px;
    line-height: 20px;
    color: #1FFC33;
}

.cancel_sub_heading {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 0 80px;
}

.cancelModal_btn {
    width: 340px;
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
}

/* ---------------------------------------complain-modal----------------------- */
.modal_backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1090;
    height: 100%;
}

.modal_content {
    width: 90%;
    max-width: 1400px;
    overflow-y: scroll;
    min-height: 200px;
    background-color: #1E2022;
    color: #FFFFFF;
    padding: 25px;
}

.modal_content::-webkit-scrollbar {
    display: none;
}

.modal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 20px; */
}

.modal_header h3 {
    margin: 0;
}

.ModalText_center {
    text-align: center;
}

.modal_close_button {
    font-size: 1.4rem;
    font-weight: 700;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    background: transparent;
}

.main_search_div {
    position: relative;
}

.inputSearch {
    width: 100%;
    padding: 10px 10px 10px 30px;
    outline: none;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    /* position: relative;  */
}

.search_icon {
    position: absolute;
    margin-left: 10px;
    margin-top: 12px;
    width: 15px;
    height: 15px;
}

.main_search_div h3 {
    text-align: center;
    margin-top: 0;
}

.main_search_div p {
    text-align: center;
    color: darkgray;
}

.locations {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
}

.locations h4 {
    margin: 0;
}

.table_main {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

.table_th_details {
    width: 100px;
    border: 1px solid #ddd;
    text-align: left;
    text-align: center;
    color: #FFFFFF;
}

.table_th {
    border: 0.5px solid #ddd;
    text-align: left;
    padding: 0 15px;
    height: 40px;
    color: #FFFFFF;
}

.table_th_Sno {
    width: 42px;
    border: 1px solid #ddd;
    text-align: left;
    text-align: center;
    color: #FFFFFF;
}


.table_description {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orderItemNo {
    min-width: 70px;
    text-align: left;
    padding: 0 12px;
    border: none;
}

.table_description {
    width: 70%;
}

.orderProductName {
    text-align: left;
    border: none;
}

/* @media only screen and (max-width: 991px) {
    .modal_content {
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .modal_content {
        width: 90%;
    }
}
*/

@media only screen and (max-width: 599px) {
    .modal_content {
        width: 100%;
        padding: 20px;
        position: absolute;
        bottom: 0;
    }
}