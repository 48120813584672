.dashboard_select_overall {
    font-size: 12px;
    display: grid;
    grid-template-columns: auto auto auto;
    margin: 5px;
}

.dashboard_overall_select {
    /* width: 121.16px; */
    /* width: 33%; */
    height: 35px;
    border: 1px solid #000000;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    cursor: pointer;
}

.days_active {
    background: #000;
    color: #fff;
    /* width: 121.16px; */
    height: 35px;
    border: 1px solid #000000;
    border-radius: 3px;
    margin: 5px;
}

.address_fs {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
}

.order_heading {
    font-weight: 500;
    font-size: 20px;
    color: #040404;
    line-height: 18px;
    margin-bottom: 0;
    /* margin-top: 15px; */
}

.order_div {
    width: 100%;
    height: 40px;
    outline: none;
    background: #F3F6F8;
    border-radius: 3px;
    padding-left: 10px;
    margin-bottom: 6px;
}

.order_input_textarea {
    width: 100%;
    height: 60px;
    outline: none;
    border: none;
    background: #F3F6F8;
    border-radius: 3px;
    padding-left: 10px;
    margin: 4px 0;
}

.order_input {
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    background: #F3F6F8;
    border-radius: 3px;
    padding-left: 10px;
    margin: 5px 0;
}

.AddDealer_input {
    width: 100%;
    height: 36px;
    outline: none;
    border: none;
    background: #F3F6F8;
    border-radius: 3px;
    padding-left: 10px;
    /* margin: 5px 0; */
}

.card_input {
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    background: #F3F6F8;
    border-radius: 3px;
    padding-left: 6px;
    margin: 0;
    font-weight: 600;
    color: #000;
}
.card_input_description {
    width: 100%;
    height: 30px;
    outline: none;
    border: none;
    background: #F3F6F8;
    border-radius: 3px;
    padding-left: 10px;
    margin: 0;
    /* font-weight: 600; */
}

.qtyRatePer_input {
    width: 25%;
    height: 36px;
    outline: none;
    border: none;
    background: #F3F6F8;
    border-radius: 3px;
    padding-left: 10px;
    margin: 0;
}

.date_margin_top {
    margin-top: 128px;
}

.dealerMargin_top {
    margin-top: 178px;
}

.dispatch_select {
    color: #000;
    font-size: 14px;
    /* padding: 10px; */
}

.dealer_select {
    color: #000;
    font-size: 14px;
    padding: 10px;
}

.order_color {
    color: #24ABF8;
}

.order_lebel {
    font-size: 14px;
    margin-top: 6px;
}

.card_lebel {
    font-size: 13px;
    font-weight: 600;
}

.saleOrders_btns {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    /* padding-bottom: 6px; */
}

.saleStep1_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 2px;
}

.saleStep2_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 0px;
    padding: 0 10px;
}

.saleOrderStep2_btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* align-items: center; */
    /* position: absolute; */
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 0px;
    padding: 0;
    /* padding-bottom: 20px; */
}

.estimateStep2_btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 0px;
    padding: 0 10px;
    font-size: 12px;
    display: grid;
    grid-template-columns: auto auto auto;
    margin: 5px;
}

.select_packer {
    color: #000;
    background: #F3F6F8;
    width: 100%;
    height: 40px;
    outline: none;
    border-radius: 3px;
    margin: 0;
    text-align: left;
    font-weight: 600;
}

.addDealer_select {
    color: #000;
    background: #F3F6F8;
    width: 100%;
    height: 36px;
    outline: none;
    border-radius: 3px;
    margin: 0;
    text-align: left;
    font-weight: 600;
}

.orders_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: 13px;
}

.voucherNo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 10px; */
    font-size: 13px;
    margin-right: 2px;
}

.orders_voucherNo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /* margin-top: 10px; */
    /* font-size: 13px; */

}

.card_qty_rate_per {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    font-size: 13px;
}

.card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    font-size: 13px;
}

.order_btn {
    border: 1px solid #000000;
    outline: none;
    background: #fff;
    border-radius: 3px;
    width: 50%;
    height: 40px;
    margin: 0 10px;
}

.item_btn {
    border: 1px solid #000000;
    outline: none;
    background: #fff;
    border-radius: 3px;
    width: 80px;
    height: 32px;
    margin: 0 10px;
    font-size: 12px;
    font-weight: bold;
}

.order_btn1 {
    border: 1px solid #000000;
    outline: none;
    background: #000;
    border-radius: 3px;
    color: #ffff;
    width: 50%;
    height: 40px;
    margin: 0 10px;
}

.order_margin {
    min-height: calc(100vh - 175px);
    margin: 10px;
    position: relative;
}

.order_container {
    min-height: calc(100vh - 220px);
    margin: 10px;
    margin-bottom: 0px;
    position: relative;
}

.step_1_main {
    min-height: calc(100vh - 115px);
    margin: 10px;
    position: relative;
    margin-top: 2px;
}

.order_container_step_1 {
    min-height: calc(100vh - 115px);
    margin: 10px;
    margin-top: 2px;
    position: relative;
}

.step_2_main {
    min-height: calc(100vh - 180px);
    position: relative;
    padding: 0 10px;
}

.order_container_step_2 {
    /* min-height: calc(100vh - 164px);
    position: relative; */
    padding: 0 10px;
}

.card_input_per {
    color: #000;
    background: #F3F6F8;
    width: 76px;
    height: 40px;
    outline: none;
    border-radius: 3px;
    margin: 0;
    text-align: center;
    font-weight: 600;
    border: none;
}

.card_input_qty {
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    background: #F3F6F8;
    border-radius: 3px;
    margin: 0;
    text-align: center;
    font-weight: 600;
}

.card_input_Rate {
    width: 80px;
    height: 30px;
    outline: none;
    border: none;
    background: #F3F6F8;
    border-radius: 3px;
    margin: 0;
    text-align: center;
    font-weight: 600;
    color: #000;
}

.border {
    background: #FFFFFF;
    border: 0.5px solid #C5C5C5;
    border-radius: 3px;
    padding: 0px 5px 12px 5px;
}

.item_scroll {
    border-top: 1px solid #C5C5C5;
    border-bottom: 1px solid #C5C5C5;
    display: block;
    max-height: calc(100vh - 344px);
    min-height: calc(100vh - 344px);
    overflow-y: scroll;
    /* margin: 0 10px 10px 10px; */
}

.item_scroll::-webkit-scrollbar {
    display: none;
}

.item_card_main {
    border: 0.5px solid #C5C5C5;
    border-radius: 3px;
    position: relative;
    padding: 0px 5px 5px 5px;
    margin-bottom: 5px;
}

.item_card_div {
    background: #FFFFFF;
    border: 0.5px solid #C5C5C5;
    border-radius: 3px;
    padding: 0px 5px 5px 5px;
    margin-bottom: 5px;
    position: relative;
}

.card_Close {
    position: absolute;
    right: 0;
    padding-right: 5px;
}

.card_Close svg {
    width: 20px;
}

.btn {
    margin: 20px;
    text-align: center;
}

.item_btn_main {
    margin: 10px 0px 0px 0px;
    text-align: center;
}

.inputdiv {
    width: 76px;
    height: 23px;
    background: #F3F6F8;
    border-radius: 3px;
    border: none;
    outline: none;
}

.borderdiv {
    width: 92px;
    height: 28px;
    left: 36px;
    top: 613.53px;
    border: 0.5px dashed #1E2022;
}

.charges_input {
    box-sizing: border-box;
    width: 60px;
    height: 23px;
    background: #F3F6F8;
    border-radius: 3px;
    border: none;
    text-align: center;
    outline: none;
}

.toPay_hide {
    visibility: hidden;
    width: 50px;
}

.toPay {
    font-size: 11px;
    margin: 0 3px;
    font-weight: bold;
}

.charges_txt {
    min-width: 72px;
    text-align: end;
}

.totalAmount_card {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    margin: 10px 0px 8px 0px;
    padding: 0.25rem;
    border-radius: 3px;
    font-size: 14px;
}

.shipingCharges {
    min-width: 120px;
}

.adjustment {
    margin-left: 4px;
    width: 92px;
    outline: none;
    height: 23px;
    border: 1px dashed #000;
    text-align: center;
}

.status_card {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    /* margin: 5px 10px; */
    padding: 0.25rem;
    border-radius: 3px;
    font-size: 13px;
    min-height: 100px;
    margin-bottom: 8px;
}

.status_card span {
    min-width: 80px;
    margin: 0 5px;
}

.addReceipt_btn {
    box-sizing: border-box;
    border: 0.5px solid #C5C5C5;
    border-radius: 3px;
    height: 30px;
    font-weight: 600;
    outline: none;
}

.back_btn {
    outline: none;
    box-sizing: border-box;
    border: 1px solid #000000;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    background-color: #fff;
    /* margin: 0 4px; */
    height: 36px;
    width: 49%;
    margin-bottom: 7px;
}

.estimateback_btn {
    outline: none;
    box-sizing: border-box;
    border: 1px solid #000000;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    background-color: #fff;
    /* margin: 0 4px; */
    height: 36px;
    width: 100%;
    max-width: 104px;
    min-width: 104px;
    /* margin-right: 14px; */
    margin-bottom: 10px;
}

.saveAsDraft {
    outline: none;
    box-sizing: border-box;
    border: 1px solid #000000;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    background-color: #fff;
    /* margin: 0 14px; */
    height: 36px;
    width: 49%;
    min-width: 104px;
    margin-bottom: 7px;
}

.save_send {
    outline: none;
    box-sizing: border-box;
    border: 1px solid #000000;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    background-color: #000;
    /* margin: 0 4px; */
    height: 36px;
    color: #FFF;
    width: 49%;
    min-width: 88px;
    /* margin-left: 14px; */
}

.estimate_save_send {
    outline: none;
    box-sizing: border-box;
    border: 1px solid #000000;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    background-color: #000;
    /* margin: 0 4px; */
    height: 36px;
    color: #FFF;
    width: 100%;
    max-width: 104px;
    min-width: 104px;
    margin-bottom: 10px;
    /* margin-right: 14px; */
}

.date_source {
    font-size: 12px;
}

.whereHouse_select_box {
    color: #000;
    width: 100%;
    font-size: 11px;
    font-weight: bold;
    border: 1px solid #C5C5C5;
    border-radius: 3px;
    /* margin-right: 20px; */
}

.recipt_status_red {
    color: #F93E3E;
}

.recipt_status_green {
    color: #1FFC33;
}

.recipt_status_pending {
    color: orange;
}

.receiptList_scroll {
    display: block;
    max-height: 60px;
    min-height: 60px;
    overflow-y: scroll;
}

.receiptList_scroll::-webkit-scrollbar {
    display: none;
}

.live_search_input {
    /* display: flex;
    justify-content: space-between; */
    position: relative;
}

.position_relative {
    position: relative;
}

.warehouseName {
    position: absolute;
    width: 56%;
    display: flex;
    right: 0;
    font-size: 13px;
    font-weight: 600;
    justify-content: space-between;
    padding: 5px 5px 0 0;
}

.table_th_Sno {
    font-weight: 600;
}

.L_U_clip {
    display: flex;
    justify-content: space-between;
    margin: 4px 0;
}

.L_clip_price {
    background: #F3F6F8;
    border-radius: 3px;
    width: 100%;
    margin-right: 10px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    display: flex;
    justify-content: space-around;
}

.L_clip_qty {
    background: #F3F6F8;
    border-radius: 3px;
    border: none;
    width: 64px;
    text-align: center;
    outline: none;
}

.table_th_Sno {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 30px;
}

.item_description {
    font-size: 14px;
}

.add_dealer_btn {
    color: #24ABF8;
    line-height: 14px;
}
.message {
    text-align: center;
    margin: 30px 0;
    font-size: 24px;
}

.massageModal_btn {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
}

.pincode_address {
    position: absolute; 
    padding-left: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.moveToSale_btn {
    outline: none;
    box-sizing: border-box;
    border: 1px solid #000000;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    background-color: #FFFFFF;
    /* margin: 0 4px; */
    height: 36px;
    color: #000000;
    width: 100%;
    max-width: 104px;
    min-width: 104px;
    margin-bottom: 10px;
    /* margin-right: 14px; */
}

.moveToSale_btn_no {
    outline: none;
    box-sizing: border-box;
    border: 1px solid #FFFFFF;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    background-color: #000000;
    /* margin: 0 14px; */
    height: 36px;
    color: #FFFFFF;
    /* width: 100%; */
}

.link_icon {
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    padding: 10px;
}

.paymentLink_input {
    background-color: #000;
    border: 1px solid #FFFFFF;
    width: 100%;
    padding: 7px 5px;
    border-radius: 5px;
    color: #FFFFFF;
}

.country_plus {
    position: absolute;
    padding-left: 5px;
    height: 40px;
    font-size: 23px;
    z-index: 1;
}

.country_code {
    position: absolute;
    font-size: 16px;
    height: 40px;
    padding-left: 16px;
    z-index: 999;
}

.country_code_select {
    -webkit-appearance: none;
    cursor: pointer;
}

.country_code_select option {
    background: #000;
    color: #FFFFFF;
}

.country_slash {
    position: absolute;
    padding-left: 32px;
    height: 40px;
    font-size: 23px;
    z-index: 1;
}

.copyLink {
    background: #FFFFFF;
    color: #000000;
    width: 120px;
    text-align: center;
    height: 40px;
    position: absolute;
    right: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
    padding-top: 8px;
}

.copyLinkBtn {
    background: gray;
    color: #000000;
    width: 120px;
    text-align: center;
    height: 40px;
    position: absolute;
    right: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
    padding-top: 8px;
}

.link_icon {
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    padding: 10px;
}

.copyLink svg {
    width: 20px;
    height: 20px;
    padding-bottom: 2px;
}

.copyLink a {
    text-decoration: none;
    color: #000;
    width: 10px;
    font-weight: 600;
}

.copyLinkBtn svg {
    width: 20px;
    height: 20px;
    padding-bottom: 2px;
}

.copyLinkBtn a {
    text-decoration: none;
    color: #000;
    width: 10px;
    font-weight: 600;
}


.whatsapp_input {
    background-color: #000;
    border: 1px solid #FFFFFF;
    width: 100%;
    padding: 7px 42px;
    border-radius: 5px;
    color: #FFFFFF;
}

.delivery_method {
    color: #000;
    background: #F3F6F8;
    width: 100%;
    height: 40px;
    outline: none !important;
    border-radius: 3px;
    margin: 0;
    text-align: left;
    font-weight: 600;
    line-height: 40px;
    border: none;
    padding-left: 5px;
    text-decoration: none;
}

.message_cancelOrder {
    text-align: center;
    margin: 30px 0;
    font-size: 17px;
}

.reqIssue {
    background-color: #000;
    border: 1px solid #FFFFFF;
    width: 80%;
    padding: 0px 5px;
    border-radius: 5px;
    color: #FFFFFF;
    margin: 0px auto 20px;
    display: flex;
    outline: none;
}