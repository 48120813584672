.right_main {
    width: 100%;
    background-color: transparent;
    color: #ffffff;
    padding: 0 15px;
}

.invoice_right_main {
    width: 100%;
    background-color: #1E2022;
    color: #ffffff;
    margin: 15px;
    border-radius: 10px;
    padding: 0 15px;
}

.invoice_dragDrop {
    width: 100%;
    opacity: 100;
    color: #ffffff;
    margin: 15px;
    border-radius: 10px;
    padding: 0 15px;
    border: 2px dashed #ccc;
    padding: 10px;
    overflow: auto;
}

.scroll_main_div {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 156px);
}

.scroll_main_div::-webkit-scrollbar {
    display: none;
}

.dispatchDashboard_header {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0;
    /* line-height: 1.5rem; */
    justify-content: space-between;
    align-items: center;
}

.dispatchHeading {
    font-weight: 600;
    font-size: 30px;
    margin: 6px 0 0px 0;
}
.Invoices_Heading {
    font-weight: 600;
    font-size: 26px;
    margin: 6px 0 20px 0;
}

.celender_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-right: 40px;
}

.reload_txt {
    font-size: 12px;
    line-height: 14px;
}


.dashboard_header_select_week {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 310px;
    justify-content: center;
}

.recipt_tabs_div {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    min-width: 310px;
    justify-content: center;
    padding: 0 6px;
}

.dashboard_days_select {
    width: 100px;
    text-align: center;
    border-radius: 25px;
    padding: 10px 0px;
    cursor: pointer;
}

.dashboard_select_overall {
    margin: auto;
    height: 46px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 216px;
    justify-content: center;
}

.dashboard_overall_select {
    width: 102px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;

}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
}

.startEndDate {
    height: 40px;
    width: 130px;
    border-radius: 6px;
    border: none;
    outline: none;
    text-align: center;
    border: 1px solid #3A3A3A;
    background: #000;
    color: #FFFFFF;
}

.startEndDate_div {
    margin-left: 40px;
}

.dashboard_main_div {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 156px);
}

.dashboard_main_div::-webkit-scrollbar {
    display: none;
}

.statusCard_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}
.Invices_statusCard_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: 10px 0; */
    margin-bottom: 5px;
}

.statusCard {
    box-sizing: border-box;
    width: 11.8%;
    height: 120px;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.packed_active {
    border: 3px solid #FFC776;
}

.cancel_status {
    border: 3px solid #FFFFFF;
}

.inProgress_active {
    border: 3px solid #DDE100;
}

.Dispatches_active {
    border: 3px solid #1FFC33;
}

.danger_active {
    border: 3px solid #F32D3C;
}

.Pending_active {
    border: 3px solid #F32D3C;
}

.newOrder_active {
    border: 3px solid #24ABF8;
}

.orange_active {
    border: 3px solid #F56221;
}

.statusNew {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #24ABF8;
}

.statusAll {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}

.statusYellow {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #DDE100;
}

.statusWhite {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
}

.statusOrange {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}
.statusReupload {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFC776;
}

.statusRed {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F32D3C;
}

/* .statusBlue {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFC776;
} */

.statusGreen {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #1FFC33;
}

.statusTxt {
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: center;
}

.dashboard_search_div {
    position: relative;
    width: 8%;
}

.dashboard_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 30px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 100%;
    /* min-width: 270px; */
}

.dashboard_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
}

.dashboard_header_select_week {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 310px;
    justify-content: center;
}

.dashboard_days_select {
    width: 100px;
    text-align: center;
    border-radius: 25px;
    padding: 10px 0px;
    cursor: pointer;
}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
}

.dashboard_Sort_By {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard_sortBy_txt {
    font-size: 16px;
    padding-right: 8px;
}

.dashboard_sortBy_select {
    border: 2px solid #1E2022;
    height: 44px;
    width: 100px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 2px;
    border-radius: 6px;
    text-align: left;
    font-size: 13px;
}

.dashboard_sortBy_select option {
    text-align: left;
    background: #1E2022 !important;
    color: #FFFFFF;
}

.dashboard_sortBy_color {
    color: #000000;
    background-color: #ffffff !important;
}

.dispatchOrders_height {
    overflow-y: scroll;
    max-height: 528px;
    min-height: 528px;
}

.dispatchOrders_height::-webkit-scrollbar {
    display: none;
}

table.itemTable {
    caption-side: bottom;
    /* border-collapse: unset; */
    border-spacing: 0 10px;
    width: 100% !important;
}

tr.item {
    border-color: #000;
    border-style: solid;
    border-width: 10px 0;
    line-height: 3 !important;
    background-color: #1E2022;
    font-weight: 300;
    font-size: 16px;
    padding: 20px;
    text-align: left;
    cursor: pointer;
}

.item td,
th {
    padding: 0 10px;
    border-color: #1E2022;
    text-align: left;
}

table.itemTable {
    caption-side: bottom;
    /* border-collapse: unset; */
    border-spacing: 0 10px;
    width: 100% !important;
}

.item td:first-child,
th:first-child {
    border-radius: 7px 0 0 7px;
    text-align: left;
}

.item td:last-child,
th:last-child {
    border-radius: 0 7px 7px 0;
}


.noData {
    width: 100%;
    max-height: 528px;
    min-height: 528px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.source_header_select_week {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 250px;
    justify-content: center;
}

.dashboard_days_select {
    width: 100px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    margin: 0 5px;
    cursor: pointer;
}

.source_days_select {
    width: 80px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;
}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
}

.ordersName_truncate {
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-transform: capitalize;
    max-width: 220px;
    position: relative;
    /* text-align: center; */
}


.table_txt_green {
    color: #05FF00;
    font-weight: 600;
}

.table_txt_orange {
    color: #FFC776;
    font-weight: 600;
}

.table_txt_yellow {
    color: #DDE100;
    font-weight: 600;
}

.table_txt_blue {
    color: #2196F3;
    font-weight: 600;
}

.table_txt_red {
    color: #FF0D0D;
    font-weight: 600;
}

.checkbox {
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 0 6px;
    padding-bottom: 5px;
}

.checkbox_empty {
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 0 14px;
    padding-bottom: 5px;
}

.fixTableHead_packing {
    margin-top: 28px;
    overflow-y: auto;
    min-height: 18.5vh;
    color: #fff;
    width: 100%;
    /* border-top: 1px solid #fff;
    border-bottom: 1px solid #fff; */
    /* border: 1px solid #fff; */
}

.fixTableHead_packing thead th {
    position: 'sticky';
    top: -2px;
}

.fixTableHead_packing table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #fff;
    /* height: 46vh; */
}

.fixTableHead_packing thead th {
    background: #000;
    border-top: none !important;
    padding: 5px 8px;
    border: 1px solid #fff;
}

.h10 {
    height: 10px;
}

.h10 td {
    padding: 4px 8px;
    border: 1px solid #fff;
    /* border-bottom: none!important; */
}

.itemDescription_div {
    display: flex;
    width: 60%;
    justify-content: space-between;
}

.d_flex {
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    margin-top: 8px;
}

.addRecipt_div {
    width: 45%;
    min-height: 130px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    padding-bottom: 4px;
}

.receipt_scroll {
    margin-top: 5px;
    max-height: 150px;
    overflow-y: scroll;
}

.receipt_scroll::-webkit-scrollbar {
    display: block !important;
    background-color: #fff;
    width: 7px;
}

.receipt_scroll::-webkit-scrollbar-thumb {
    background-color: #000;
}

.payment_detailsDiv {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* height: 100%; */
    width: 100%;
    padding: 0 12px;
    font-size: 14px;
}


.recipt_statusWidth {
    width: 210px;
}

.recipt_status_red {
    color: #F93E3E;
}

.recipt_status_green {
    color: #1FFC33;
}

.recipt_status_pending {
    color: yellow;
}

.subTotal_flex_col {
    width: 45%;
    min-height: 130px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.subTotal_div {
    width: 92%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
}

.shipingCharges_input {
    box-sizing: border-box;
    background-color: transparent;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    font-style: normal;
    font-weight: 300 !important;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    width: 100px;
    text-align: center;
    letter-spacing: 1px;
    outline: none;
}

.fixSize_input {
    width: 73px;
    text-align: right;
}

.adjustment {
    box-sizing: border-box;
    border: 0.5px dashed #F3F3F3;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: #C2C2C2;
    background-color: transparent;
    text-align: center;
    outline: none;
    width: 138px;
}

.subTotal_txtRed {
    color: red;
    width: 80px;
    text-align: right;
}

.totalINR_div {
    width: 92%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
}

.orders_btn_div {
    display: flex;
    justify-content: space-around;
    width: 64%;
    margin: auto;
    margin-top: 32px;
    padding-bottom: 20px;
}

.viewDetail_btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.uploadData_btn {
    min-width: 112px;
    background-color: transparent;
    border: 1px solid #1FFC33;
    border-radius: 5px;
    padding: 10px 0;
    color: #1FFC33;
    font-size: 13px;
    cursor: pointer;
    text-align: center;
    line-height: 24px;
}