.bg_white {
    background-color: #fff;
    color: #18191B;
    width: 100%;
}

.bg_dark {
    background-color: #000000;
    color: #fff;
    width: 100%;
}

.main_heading {
    line-height: 56px;
    padding-left: 20px;
    font-size: 30px !important;
    margin: 0;
}

.orderCards_mainDiv {
    width: 100%;
}

.row {
    display: flex;
    padding: 0 10px;
    flex-wrap: wrap;
}

.col {
    flex: 20%;
    padding: 10px;
    min-width: 300px;
    max-width: 346px;
}

.scroll_height {
    height: 416px;
    overflow-y: scroll;
    margin-bottom: 10px;
}

.orderCard {
    background-color: #1E2022;
    border-radius: 8px;
    padding: 2px 10px;
}

.orderCard_light {
    background-color: #FFFFFF;
    border: 1px solid gray;
    border-radius: 8px;
    padding: 2px 10px;
}

.orderCard_heading {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    /* color: #FFFFFF; */
    margin-bottom: 0;
    text-align: center;
}

.orderCard_subHeading {
    text-align: center;
    margin-top: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* color: #DDDDDD; */
    margin-bottom: 0;
}

.productCard {
    box-sizing: border-box;
    padding: 0px 10px;
    padding-top: 1px;
    width: 100%;
    height: 96px;
    background: #18191B;
    border-radius: 5px;
    margin-top: 0.5rem;
    cursor: pointer;
}

.productCard_light {
    box-sizing: border-box;
    padding: 0px 10px;
    padding-top: 1px;
    width: 100%;
    height: 96px;
    left: 107px;
    top: 271px;
    background: #FFFFFF;
    border: 1px solid gray;
    border-radius: 5px;
    margin-top: 0.5rem;
    cursor: pointer;
}

.justifyContentBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
}

.panelCart_txt {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-transform: capitalize;
    color: #9D9D9D;
}

.orderCard_date {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 14px;
    text-transform: uppercase;
    /* color: #9D9D9D; */
    display: flex;
    justify-content: left;
    align-items: center;
}

.ml_10px {
    margin-left: 10px;
}

.productName {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    /* color: #FFFFFF; */
}

.orderId {
    font-style: normal;
    font-weight: 300;
    font-size: 12.1905px;
    line-height: 15px;
    text-transform: capitalize;
    /* color: #FFFFFF; */
}

.status_orange {
    color: orange;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
}

.status_yellow {
    color: yellow;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
}

.status_red {
    color: red;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
}

.status_green {
    color: #05FF00;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
}

.status_#05FF00 {
    color: green;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
}

.view {
    text-decoration-line: underline;
}

/* ---------------------------------------products-item-Modals--------------------------------------------- */

.modal {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    padding-top: 180px;
}

.modal_content {
    width: 90%;
    max-width: 800px;
    background: #1E2022;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: auto;
    padding-top: 2px;
    color: #FFFFFF;
}

.modal_content_light {
    width: 90%;
    max-width: 800px;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: auto;
    padding-top: 2px;
    border: 1px solid gray;
    color: #000000;
}

.modal_close_div {
    width: 100%;
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.5rem;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.productName_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    letter-spacing: 1px;
    padding: 4px 20px 0 20px;
}

.modal_heading {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 6px;
}

.product_date {
    font-style: normal;
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    text-transform: uppercase;
    /* color: #9D9D9D; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 1px;
    padding: 0 20px;
}

.itemCard_div {
    overflow-y: scroll;
    height: 420px;
    padding: 0 20px;
}

.itemCard {
    margin-top: 1rem;
    width: 100%;
    height: 110px;
    background: #18191B;
    border-radius: 6.62606px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.itemCard_light {
    margin-top: 1rem;
    width: 100%;
    height: 110px;
    background: #FFFFFF;
    border-radius: 6.62606px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid gray;
}

.itemCard_left {
    display: flex;
    width: 40%;
}

.itemImage {
    width: 74px;
    height: 90px;
    margin: 0 12px;
}

.itemDetail_div {
    width: 100%;
    letter-spacing: 2px;
}

.itemDetail {
    display: flex;
}

.itemName_txt {
    width: 50%;
    font-size: 16px;
    font-weight: 400;
}

.itemName {
    width: 50%;
    /* padding-right: 8px; */
}

.itemCard_right {
    padding-right: 16px;
}

.modalFooter_div {
    width: 100%;
    height: 100px;
    background: #0f0f0f00;
    border-bottom-left-radius: 6.62606px;
    border-bottom-right-radius: 6.62606px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalFooter_div_light {
    width: 100%;
    height: 100px;
    background: #FFFFFF;
    border-bottom-left-radius: 6.62606px;
    border-bottom-right-radius: 6.62606px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
}

.modalFooter_btn {
    width: 55%;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
}

.modalFooter_GTotal {
    width: 45%;
    padding: 0 20px;
}

.borderBottom_div {
    border-bottom: 1px solid #6B6B6B;
    letter-spacing: 1px;
}

.total_div {
    display: flex;
    justify-content: space-between;
}

.total_rs {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
}

.grandTotal {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 33px;
    text-transform: capitalize;
    /* color: #FFFFFF;  */
}

/* -------------------------------------------orders------------------------------------------------------- */

.main_div_white {
    background-color: #FFFFFF;
    width: 100%;
}

.main_div_dark {
    background-color: #000000;
    width: 100%;
    color: #FFFFFF;
    padding: 16px;
}

.main_container {
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    padding: 0 50px
}

.orderHeading {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.08em;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 10px;
}

.dealer_header_select_days {
    height: 44px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 320px;
    justify-content: center;
}

.dealer_days_select {
    width: 102px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;
    color: gray;
}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
    color: #000;
}

.tabs_div {
    width: 100%;
    display: flex;
    justify-content: right;
}

.d_flex {
    display: flex;
    justify-content: space-between;
}

.orderContent_div {
    width: 40%;
}

.orderInput_div {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    margin-top: 20px;
    position: relative;
}

.inputField {
    box-sizing: border-box;
    width: 65%;
    background: transparent;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
    min-height: 36px;
    outline: none;
}

.inputField_textArea {

    box-sizing: border-box;
    width: 65%;
    background: transparent;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
    height: 80px;
    outline: none;
}

.order_txt {
    line-height: 30px;
}

.voucherDate_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 65%;
}

.table_main {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

.table_th {
    border: 0.5px solid #ddd;
    text-align: left;
    padding: 0 15px;
    height: 40px;
}

/* .create_new_item {
    border: 0.5px solid #ddd;
    text-align: left;
    padding: 0 26px;
    height: 40px;
    line-height: 40px;
    text-decoration-line: underline;
    cursor: pointer;
} */

.table_description {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orderItemNo {
    min-width: 70px;
    text-align: left;
    padding: 0 12px;
}

.orderProductName {
    text-align: left;
    width: 70%;
}

.ViewImageIcon {
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
    text-decoration-line: underline;
    padding-bottom: 1px;
    padding-top: 1px;
    cursor: pointer;
}

.table_th_details {
    width: 150px;
    border: 1px solid #ddd;
    text-align: left;
    text-align: center;
}

.paymentMethod_div {
    width: 45%;
    min-height: 130px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.paymentMethod_cod_div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 26px;
    width: 60%;
}

.payment_cod {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.payment {
    width: 38%;
}

.cod {
    width: 62%;
    box-sizing: border-box;
    background: #1E2022;
    outline: none;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
    height: 36px;
}

.status_received {
    display: flex;
    justify-content: space-between;
}

.status {
    width: 38%;
}

/* .recieved {
   
} */

.addPayment_div {
    width: 40%;
    text-align: center;
}

.addPament_btn {
    border: 1px solid #1FFC33;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 36px;
    color: #FFFFFF;
    padding: 0 32px;
    background-color: transparent;
    border-radius: 6px;
}

.recieved {
    width: 62%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-decoration-line: underline;
    color: #1FFC33;
}


.paymentPending {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #F93E3E;
    text-decoration-line: underline;
    cursor: pointer;
}

.subTotal_flex_col {
    width: 45%;
    min-height: 130px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.subTotal_div {
    width: 92%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
}

.totalINR_div {
    width: 92%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
}

.subTotal_txtRed {
    color: red;
}

.adjustment {
    box-sizing: border-box;
    border: 0.5px dashed #F3F3F3;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: #C2C2C2;
    background-color: transparent;
    text-align: center;
    outline: none;
    width: 138px;
}

.shipingCharges_input {
    box-sizing: border-box;
    background-color: transparent;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    font-style: normal;
    font-weight: 300 !important;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    width: 100px;
    text-align: center;
    letter-spacing: 1px;
}

.orders_btn_div {
    display: flex;
    justify-content: space-around;
    width: 60%;
    margin: auto;
    margin-top: 40px;
    padding-bottom: 40px;
}

.viewImage_txt {
    line-height: 10px;
}

.search_icon {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding-right: 10px;
    cursor: pointer;
}

.crossIcon {
    position: absolute;
    padding: 8px 0 0 8px;
    cursor: pointer;
}

.No_Data {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 560px);
}

.showItems_Maindiv {
    position: absolute;
    max-width: 1920px;
    margin: auto;
    top: 0;
    padding-top: 549px;
    padding-left: 154px;
}

.showItems_div {
    background-color: #fff;
    height: 290px;
    width: 500px;
    padding: 0 6px;
}

.search_items_div {
    width: 95%;
    padding-top: 6px;
    margin-bottom: 10px;
}

.search_items {
    box-sizing: border-box;
    width: 100%;
    outline: none;
    font-size: 18px;
    line-height: 28px;
}

.closeItems {
    color: #aaaaaa;
    float: right;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.5rem;
    cursor: pointer;
}

.closeItems:hover,
.closeItems:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.items {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    text-align: right;
    color: #464255;
    padding: 0 10px;
    cursor: pointer;
}

.item_active {
    height: 34px;
    background: #606060;
    border-radius: 3px;
    color: #FFFFFF;
}

/* -----------------------------------Search-select---------------------------------------------- */
.main_div {
    width: 100%;
}

.search_box {
    width: 100%;
}

/* -------------------------------------------add-new-item-------------------------------------------------- */

.add_modal {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    padding-top: 130px;
}

.add_modal_content {
    width: 90%;
    max-width: 520px;
    height: 680px;
    margin: auto;
    padding: 10px;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
}

.add_modal_close_div {
    width: 100%;
}

.add_close {
    color: #aaaaaa;
    float: right;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.5rem;
    padding-right: 4px;
}

.add_close:hover,
.add_close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modalMain_heading {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #FFFFFF;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0px;
}

.inputFields_div {
    width: 85%;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20px;
}

.inputFields_heading {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 2px;
    color: #FFFFFF;
}

.inputField_box {
    box-sizing: border-box;
    background-color: #000000;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 36px;
    font-size: 15px;
    font-weight: 100 !important;
    min-height: 40px;
    padding: 0 8px;
    outline: none;
}

.select_box {
    /* box-sizing: border-box;
    width: 65%; */
    background-color: #000000;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 30px;
    font-size: 15px;
    font-weight: 400;
    min-height: 40px;
    padding: 0 4px;
}

.unbold_text {
    font-weight: 100;
}

/* .select_box {
    background-color: #000000;
} */

.uploadImage_div {
    width: 85%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: auto;
    margin-top: 32px;
    align-items: center;
}

.uploadImage_btn {
    border: 1px solid #1FFC33;
    background-color: transparent;
    color: #FFFFFF;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    width: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.modalItem_Image {
    width: 76px;
    height: 38px;
    object-fit: cover;
}

.delete_div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #F93E3E;
    text-align: center;
    width: 64px;
    min-height: 36px;
    padding: 4px 0;
    cursor: pointer;
    border: 3px solid #00000045;
    border-radius: 8px;
}

.modalButton_div {
    width: 372px;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: space-around;
    margin-top: 28px;
}

.view_Item_Image {
    width: 190px;
    height: 260px;
    display: flex;
    margin: 10px auto;
}

.demoImage_div {
    width: 76px;
    height: 38px;
    border: 1px solid #ffffff;
    background-color: transparent;
    padding: 4px;
}

.demoImage_bgc {
    background-color: #fff !important;
    width: 76px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.whereHouse_select_box {
    width: 65%;
    background-color: #000000;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 30px;
    font-size: 15px;
    font-weight: 400;
    min-height: 40px;
    padding: 0 4px;
}

.searchItems_box {
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    height: 40px;
    line-height: 40px;
    /* text-decoration-line: underline; */
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    padding-left: 10px;
    outline: none !important;
}

.whereHouse_select_box {
    width: 65%;
    background-color: #000000;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 30px;
    font-size: 15px;
    font-weight: 400;
    min-height: 40px;
    padding: 0 4px;
}

.modal_cod {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    padding-top: 260px;
}

.modal_viewImage {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    padding-top: 40px;
}


.modal_content_cod {
    width: 90%;
    max-width: 520px;
    min-height: 280px;
    margin: auto;
    padding: 10px;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgb(0 0 0 / 16%);
    border-radius: 10px;

}

.uploadRecieve_div {
    width: 85%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 1px;
    margin-top: 28px;
}

.selectBox_payment {
    width: 60%;
    background-color: #000000;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 30px;
    font-size: 15px;
    font-weight: 400;
    height: 44px;
    padding: 0 4px;
    letter-spacing: 1px;
    outline: none;
}

.inputField_enterAmount {
    box-sizing: border-box;
    margin: auto;
    width: 85%;
    display: flex;
    background-color: #000000;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    height: 42px;
    margin-top: 28px;
    color: #FFFFFF;
    outline: none;
    font-size: 18px;
    font-weight: 400;
    padding: 0 8px;
    text-align: center;
}

.inputField_enterAmount::placeholder {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.modalButton_cod {
    margin: 30px 0;
    text-align: center;
}

.uploadScreen {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: rgba(249, 62, 62, 0.7);
}

.inputField_enterId {
    box-sizing: border-box;
    margin: auto;
    width: 85%;
    display: flex;
    background-color: #000000;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    height: 42px;
    margin-top: 28px;
    color: #FFFFFF;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    padding: 0 8px;
}

.payment_image_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
}

.payment_image {
    box-sizing: border-box;
    width: 200px;
    height: 120px;
    border: 2.2439px dashed #FFFFFF;
    padding: 10px;
}

.viewScreenshot {
    width: 200px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #1FFC33;
    text-align: left;
    margin-top: 2px;
}

.viewImage {
    display: flex;
    width: 100%;
    margin: auto;
    height: auto;
    max-height: calc(100vh - 80px);
}

.viewImage_close {
    cursor: pointer;
    text-align: right;
    font-size: 32px;
    line-height: 8px;
}

.accept_modal {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    padding-top: 360px;
}

.accept_modal_content {
    width: 90%;
    max-width: 500px;
    background: #1E2022;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: auto;
    padding-top: 2px;
    color: #FFFFFF;
}

.accept_modal_light {
    width: 90%;
    max-width: 800px;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: auto;
    padding-top: 2px;
    border: 1px solid gray;
    color: #000000;
}

.message {
    text-align: center;
    margin: 30px 0;
    font-size: 24px;
}

.massageModal_btn {
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin: auto;
    padding-bottom: 30px;
}

.close_btn {
    color: #aaaaaa;
    float: right;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.5rem;
    padding-right: 6px;
}

.container_autocomplete {
    display: flex;
    overflow: scroll;
    width: 100%;
    max-width: 100%;
    padding-top: 6px;
    margin-bottom: 10px;
    border-radius: 5px;
    color: black;
    flex-direction: column;
  }
  
  .create_new_item {
    border: 0.5px solid #ddd;
    text-align: left;
    /* padding: 0 26px; */
    height: 20px;
    line-height: 20px;
    text-decoration-line: underline;
    cursor: pointer;
    color: #464255;
    font-weight: 500;
    padding: 4px 0;
  }
  .createNewItem_btn {
    text-align: left;
    padding: 5px;
    border-bottom: 1px solid #464255;
    cursor: pointer;
    color: #464255;
}
  .container_autocomplete input {
    box-sizing: border-box;
    width: 100%;
    /* min-width: 50%; */
    border: none;
    border-radius: 5px;
    height: 36px;
    border: 1px solid #000000;
    outline: none;
    margin-bottom: 8px;
    padding: 0 8px;
  }
  
  .list {
    height: 210px;
    overflow-y: scroll;
  }
  
  .item_active {
    cursor: pointer;
    height: 36px;
    background: #606060;
    border-radius: 3px;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 2px 8px;
  }
  
  .item_deactive {
    cursor: pointer;
    height: 36px;
    background: #FFFFFF;
    border-radius: 3px;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 2px 8px;
  }


  /* --------------------------------------------orderDashboard----------------------------------------------------- */

  .dashboard_select_overall {
    margin: auto;
    height: 46px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 216px;
    justify-content: center;
    margin-top: 16px;
}

.dashboard_overall_select {
    width: 102px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;

}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
}


  .actionPending_div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0 20px 0;
    min-height: 480px;
    padding: 0 20px;
}

.actionPending {
    width: 23%;
    box-sizing: border-box;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    min-height: 480px;
    position: relative;
}

.InactiveDealers {
    box-sizing: border-box;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    width: 77%;
    margin-left: 40px;
} 

.actionPending_heading {
    border-bottom: 1px solid #4D4D4D;
    text-align: center;
    line-height: 60px;
    font-size: 22px;
}

.takeAction {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    bottom: 0;
    height: 100%;
    padding-bottom: 24px;
    position: absolute;
}

.InactiveDealers_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px
}

.InactiveDealers_left {
    width: 40%;
}

.InactiveIcon_main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.inactiveIcon_div {
    border: 1px solid #4D4D4D;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 32px;
}

.inactiveIcon {
    width: 30px;
    height: 30px;
}

.whatsappIcon {
    width: 40px;
    height: 40px;
}





.inactive_profile_details {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 30px 0;
}

.profile_details {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    padding-bottom: 4px;
    font-size: 14px;
    min-width: 220px;
}

.inactive_dealer_img {
    margin: 0 10px;
}

.selectAll {
    float: right;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 4px;
    padding-right: 30px;
}

.textarea_css {
    background: #18191B;
    border-radius: 6.62606px;
    color: #FFFFFF;
    width: 100%;
    margin: 24px 0;
    border: 0;
    outline: none;
    padding: 10px;
    resize: none;
}

.progressBar_center {
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
}
