/* .main_container {
    color: #FFFFFF;
    width: 100%;
    padding: 0 10px;
}

.complain_header {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0;
    line-height: 1.5rem;
    justify-content: space-between;
    align-items: center;
}

.heading {
    font-weight: 500;
    font-size: 30px;
    margin: 0;
    color: #FFFFFF;
}

.complain_header_right {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.complain_search_div {
    position: relative;
    margin-right: 20px;
}

.dealer_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 30px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 100%;
    min-width: 220px;
}

.dealer_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
}

.complain_sortBy_txt {
    font-size: 20px;
    padding-right: 12px;
}

.complain_sortBy_select {
    border: 1px solid #ffffff;
    height: 49px;
    width: 234px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 12px;
}

.table_heading_div {
    height: 50px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    border: 0.5px solid #3A3A3A;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    padding: 0 40px;
}

.table_heading {
    min-width: 40%;
    text-align: center;
}

.table_heading_small {
    min-width: 15%;
    text-align: center;
}

.scrollTable {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 324px);
    min-height: calc(100vh - 324px);
}

.scrollTable::-webkit-scrollbar {
    display: none;
}

.table_content_div {
    min-height: 50px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    color: #FFFFFF;
    border-radius: 6px;
    margin: 10px 0;
    padding: 0 40px;
    cursor: pointer;
}

.table_txt {
    min-width: 40%;
    max-width: 40%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.table_txt_small {
    min-width: 15%;
    max-width: 15%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.table_txt_green {
    min-width: 16.66%;
    text-align: center;
    color: #29BB89;
}

.table_txt_yellow {
    min-width: 16.66%;
    text-align: center;
    color: #FFA32F;
}

.table_txt_red {
    min-width: 16.66%;
    text-align: center;
    color: red;
} */


/* .whatsappchats_main {
    margin: 20px auto;
    margin-top: 0;
    width: 50%;
    border: 1px solid #fff;
    border-radius: 8px;
    min-height: calc(100vh - 220px);
    background-color: #1E2022;
}

.SendMessageIcon {
    background-color: rgb(31, 252, 51);
    width: 50px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.whatsapp_message_right {
    color: #fff;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.whatsapp_message_txt {
    border: 1px solid #fff;
    padding: 5px;
    margin: 10px;
    border-radius: 5px;
    color: #fff;
    padding-right: 50px;
    position: relative;
    display: -webkit-inline-box;
}

.message_time {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 11px;
    padding-right: 4px;
} */

/* --------------------------------------------------whatsapp-css------------------------------------------------------------------------------------------ */
.whatsapp_header {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0;
    line-height: 1.5rem;
    justify-content: space-between;
    align-items: center;
}

.heading {
    font-weight: 500;
    font-size: 30px;
    margin: 0;
    color: #FFFFFF;
}

.whatsappMessage_main {
    width: 100%;
    display: flex;
    min-height: calc(100vh - 106px);
    max-height: calc(100vh - 106px);
}

.whatsappMessage_chats {
    width: 100%;
    /* border: 1px solid rgba(112, 112, 112, 1); */
    position: relative;
}

.whatsappMessage_list {
    width: 100%;
    /* min-width: 508px; */
    /* border: 1px solid rgba(112, 112, 112, 1); */
    /* border-left: none; */
    /* background-color: #0c141b; */
}

.whatsapp_search_div {
    position: relative;
    margin: 6px 10px;
    min-height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.whatsapp_search {
    border-radius: 50px;
    height: 40px;
    padding-left: 30px;
    padding-right: 36px;
    background-color: #f0f2f5;
    border: 1px solid rgba(217, 217, 217, 1);
    color: rgba(51, 51, 51, 1);
    outline-offset: 0px !important;
    outline: none;
    width: 100%;
    min-width: 220px;
}

.whatsapp_search_icon {
    position: absolute;
    right: 8px;
    top: 15px;
}

.profile_card {
    display: flex;
    color: #fff;
    width: 100%;
    align-items: center;
    padding: 0px 10px;
    cursor: pointer;
    position: relative;
}

.profile_card span {
    color: rgba(122, 122, 122, 1);
    font-size: 12px;
    font-weight: 600;
    margin: 6px 0;
}

.active_dealer_chat {
    background-color: #f0f2f5;
}

.whatsappMessage_image {
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
    border-radius: 36px;
    border: 0.5px solid gray;
}

.whatsappMessage {
    max-height: calc(100vh - 200px);
    min-height: calc(100vh - 200px);
    overflow-y: auto;
}

.whatsappMessage::-webkit-scrollbar {
    display: block;
}

.whatsappMessage::-webkit-scrollbar {
    width: 6px;
    /* Width of the scrollbar */
}

.whatsappMessage::-webkit-scrollbar-track {
    background: transparent;
    /* Color of the track (the area behind the scrollbar) */
}

.whatsappMessage::-webkit-scrollbar-thumb {
    background: gray;
    /* Color of the scrollbar thumb */
    border-radius: 4px;
    /* Rounded corners */
}

.whatsappMessage::-webkit-scrollbar-thumb:hover {
    background: gray;
    /* Color of the scrollbar thumb on hover */
}

.whatsapp_input_div {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    min-height: 60px;
    background-color: #fff;
}

.whatsapp_chats_input {
    width: 100%;
    background-color: transparent;
    border: 1px solid rgba(86, 88, 89, 1);
    height: 40px;
    color: #000;
    padding: 0 80px 0 10px;
    margin: 12px 0 0 0;
    border-radius: 4px;
    outline: none;
}

.SendMessage {
    /* position: absolute; */
    /* right: 0; */
    cursor: pointer;
    border-radius: 50px;
    min-width: 46px;
    max-width: 46px;
    min-height: 46px;
    max-height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #06a68a;
    margin: 8px 10px;
    border: none;
    /* padding-left: 6px; */
}

.whatsapp_message {
    color: #fff;
    position: relative;
    width: 100%;
    padding-top: 20px;
}

.whatsapp_message_right {
    color: #fff;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
}

.whatsapp_message_right_css {
    background-color: #e6fedc;
    border: none;
    padding: 5px;
    margin: 10px;
    border-radius: 5px;
    color: #172d15;
    padding-right: 50px;
    position: relative;
    display: -webkit-inline-box;
    max-width: 75%;
    /* padding-top: 24px; */
    min-width: 200px;
}

.whatsapp_message_right_media {
    background-color: #045c4c;
    border: none;
    /* padding: 5px; */
    margin: 10px;
    border-radius: 5px;
    color: #fff;
    padding: 5px 5px 15px 5px;
    position: relative;
    display: -webkit-inline-box;
    max-width: 88%;
    /* padding-top: 24px; */
    /* min-width: 216px; */
}

/* .whatsapp_message_right_media_css {
    background-color: #045c4c;
    border: none;
    margin: 10px;
    border-radius: 5px;
    color: #fff;
    padding: 5px 5px 15px 5px;
    position: relative;
    display: -webkit-inline-box;
    max-width: 75%;
    min-width: 216px;
} */

.whatsapp_message_txt {
    background-color: #ffffff;
    border: none;
    padding: 5px;
    margin: 10px;
    border-radius: 5px;
    color: #26292c;
    padding-right: 50px;
    position: relative;
    display: -webkit-inline-box;
    max-width: 75%;
}

.whatsapp_message_media {
    background-color: #1e2b30;
    border: none;
    /* padding: 5px; */
    margin: 10px;
    border-radius: 5px;
    color: #fff;
    padding: 5px 5px 15px 5px;
    position: relative;
    display: -webkit-inline-box;
    max-width: 75%;
}

.message_time {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 11px;
    padding-right: 4px;
}

.whatsapp_chats_header {
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); */
    padding: 5px;
    display: flex;
    color: #000;
    justify-content: center;
    align-items: center;
    background: #fff;
    max-height: 60px;
}

.chats_max_height {
    max-height: calc(100vh - 228px);
    min-height: calc(100vh - 228px);
    overflow-y: scroll;
    background-color: #f6f3ee;
    /* position: relative; */
}

.chats_max_height::-webkit-scrollbar {
    display: block;
}

.chats_max_height::-webkit-scrollbar {
    width: 6px;
    /* Width of the scrollbar */
}

.chats_max_height::-webkit-scrollbar-track {
    background: transparent;
    /* Color of the track (the area behind the scrollbar) */
}

.chats_max_height::-webkit-scrollbar-thumb {
    background: gray;
    /* Color of the scrollbar thumb */
    border-radius: 4px;
    /* Rounded corners */
}

.chats_max_height::-webkit-scrollbar-thumb:hover {
    background: gray;
    /* Color of the scrollbar thumb on hover */
}

.profile_card_message {
    min-width: 70%;
    max-width: 202px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 44px;
    color: rgba(51, 51, 51, 1);
}

.adminChats_css {
    /* overflow: auto; */
    margin-right: 40px;
}

.unreadChat {
    position: absolute;
    top: 10px;
    left: 50px;
    background: rgba(0, 214, 85, 1);
    min-width: 20px;
    max-height: 20px;
    min-height: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
}

.AddFile {
    display: flex;
    width: 100px;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.icon_css {
    margin: 0 5px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.AddFile span {
    cursor: pointer;
}

.showFileOptions {
    width: 120px;
    height: 160px;
    border: 1px solid #FFF;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    margin: 0 10px;
    color: #fff;
    padding: 6px 10px;
    margin-bottom: 60px;
    z-index: 999;
    background: #000;
}

.selectFile_css {
    cursor: pointer;
}

.selectFile_css label {
    cursor: pointer;
}

.invoice_dragDrop {
    width: 100%;
    height: 83%;
    opacity: 100;
    color: #ffffff;
    /* margin: 15px; */
    border-radius: 10px;
    padding: 0 15px;
    border: 2px dashed #ccc;
    padding: 10px;
    overflow: auto;
}

.uploadData_btn {
    font-size: 16px;
    cursor: pointer;
    width: 100%;
}

.uploadData_btn:hover {
    color: #2196F3;
}

.selected_whatsappImage {
    /* min-width: 150px; */
    max-width: 150px;
    /* min-height: 150px; */
    max-height: 150px;
    border: 0.5px solid gray;
}

.whatsappImage_remove {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
}

.chatDate {
    position: absolute;
    width: 100%;
    right: 0;
    top: 0;
    text-align: center;
    font-size: 12px;
    color: gray;
    margin-top: 4px;
}

.showDate {
    /* background-color: #fff; */
    width: 90px;
    /* border-radius: 4px; */
    display: flex;
    justify-content: center;
    margin: auto;
    color: rgba(84, 84, 84, 1);
    font-weight: 500;
    font-size: 12px;
}

.view_selectedFile_caption {
    width: 100%;
    background-color: transparent;
    border: 1px solid rgba(86, 88, 89, 1);
    /* height: 40px; */
    color: #fff;
    padding: 10px;
    margin: 12px 0 10px 0;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 250px;
    /* justify-content: flex-start;
    align-items: center; */
}

/* .view_selectedFile {
   
} */

.caption {
    /* margin-left: 16px; */
    height: 40px;
    background-color: #fff;
    border-radius: 20px;
    width: 100%;
    border: 1px solid #000;
    max-width: 300px;
    color: #000;
    padding: 0 10px 4px 10px;
    outline: none;

}

.checkbox_height {
    max-height: 4px;
    min-height: 4px;
    max-width: 16px;
    min-width: 16px;
}

.managerNameDesignation {
    position: absolute;
    top: -18px;
    left: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 5px;
    min-width: 200px;
}

.managerNameDesignation_media {
    position: absolute;
    top: -18px;
    left: -36px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 5px;
    min-width: 200px;
}

.managerName {
    font-size: 13px;
    /* font-weight: 600; */
    color: #c3c3c3
}

.designation {
    font-size: 13px;
    color: rgba(134, 134, 134, 1);
    margin-right: 5px;
}

.unreadMsg_inbox {
    display: flex;
    width: 100%;
    border: 1px solid #fff;
    margin-top: 10px;
}

.unreadMsg_inbox_div {
    margin: 10px;
}

.unreadMsg_inbox {
    display: flex;
    width: 100%;
    border: 1px solid #fff;
    height: 40px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
}

.massage_tab {
    width: 50%;
    color: #fff;
    text-align: center;
    cursor: pointer;
}

.massage_tab_active_left {
    background: #fff;
    color: #000;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    font-weight: 600;
    cursor: pointer;
}

.massage_tab_active_right {
    background: #fff;
    color: #000;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: 600;
    cursor: pointer;
}

.audio_icon {
    background-color: #fe6041;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
    margin-right: 20px;
}

.fileName {
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
    margin-bottom: 10px;
    /* padding: 0 20px; */
    width: 190px;
    color: #000;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.checkbox_css {
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.managerName_div {
    min-width: 100px;
}

.managerName_div label {
    font-size: 12px;
}

.selectManager {
    border: 0.3px solid rgba(217, 217, 217, 1);
    /* margin-top: 4px; */
    height: 24px;
    text-align: left;
    background-color: transparent;
    padding: 0px;
    line-height: 24px;
    font-size: 12px;
    color: #000;
}

.selectManager option {
    background: #fff;
}

.adminChats_css a {
    color: #0daffd !important;
    word-break: break-all;
}

.managerFilter {
    border: 1px solid #000;
    max-width: 92px;
    border-radius: 16px;
    cursor: pointer;
    color: #000;
}

.managerActive {
    color: #fff;
    background-color: #000;
}

.managerFilter_div {
    position: absolute;
    border: 1px solid #000;
    border-radius: 4px;
    margin-top: 2px;
    z-index: 999;
    min-width: 184px;
    margin-left: 80px;
}

.managerNameFilter {}

.openFile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 150px;
    height: 120px;
}

.threedot_div {
    background-color: transparent;
    border-radius: 20px;
    padding: 2px 5px 4px 5px;
    cursor: pointer;
    border: 0.5px solid gray;
}

/* .threedot_div:hover {
    background-color: #6c757d;
    border: 0.5px solid #6c757d;
} */

.threedot_size {
    font-size: 13px;
}

.markRead_div {
    position: absolute;
    border: 1px solid #000;
    border-radius: 4px;
    margin-top: 7px;
    z-index: 999;
    right: 0;
    padding: 4px 0px;
    min-width: 136px;
    background-color: #fff;
    margin-right: 10px;
}

.active_markRead {
    cursor: pointer;
    margin: 0 5px;
}

.active_markRead:hover {
    background-color: #0daffd;
    padding: 0 5px;
    border-radius: 5px;
}

.dealer_name {
    color: rgba(51, 51, 51, 1);
    margin-top: 10px;
    font-weight: 500;
}

.whatsapp_dealer_detail {
    width: 100%;
    margin-left: 10px;
    border-bottom: 1px solid #f0f2f5;
}

.scrollAll_filters {
    /* 'd-flex justify-content-between text-light px-3 mb-2' */
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding: 0 8px;
    padding-bottom: 0;
    overflow-x: scroll;
}

.scrollAll_filters::-webkit-scrollbar {
    display: block;
}

.min_filter_width {
    min-width: 108px;
    color: #000;
}

.header_storeName {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    padding-top: 0px;
}

.header_dealerName {
    font-size: 13px;
    font-weight: 400;
    line-height: 6px;
}

.header_mobileNo {
    color: #0d6efd;
    display: inline;
    font-size: 14px;
    line-height: 14px;
}

.header_status {
    font-size: 14px;
    line-height: 14px;
}

/* bg-light border text-dark me-2 rounded px-2 py-1 */
.managerName_selected {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    margin-right: 5px;
    border-radius: 50px;
    padding: 3px 4px;
}

.spinnerWhite {
    display: block;
    min-width: 5px;
    min-height: 5px;
    border: 2px solid #fff;
    border-radius: 50%;
    border-top-color: #000;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.templateContent {
    padding: 10px;
    border-radius: 8px;
    background-color: #fff;
    width: 250px;
    min-height: 50px;
    margin: 5px auto;
    position: relative;
}

.pdf_vertical {
    min-width: 10px;
    position: absolute;
    background-color: #fff;
    height: 162px;
    right: 0;
    z-index: 999;
    top: 10px;
}
.pdf_vertical_doc {
    min-width: 15px;
    position: absolute;
    background-color: #fff;
    height: 162px;
    right: 0;
    z-index: 999;
    top: 10px;
}

.mediadiv {
    background: #ccd0d5;
    width: 100%;
    height: 160px;
    border-radius: 8px;
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.websiteLink {
    text-decoration: none;
    cursor: pointer;
}

.websiteLink:hover {
    color: indigo;
}

.pdfView {
    width: 240px;
    height: 160px;
    overflow: hidden;
    overflow-y: hidden;
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 1;
}

.pdf_container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow-y: hidden;
}

.openFileTemplate {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 120px;
    color: #000;
}