.bgColor {
    background-color: #1E2022;
    border-radius: 12px;
}

.CreateManagerBtn {
    font-size: 14px;
}

.EyeIcon_div {
    position: relative;
}

.EyeIcon {
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding-right: 10px;
    cursor: pointer;
}

.iconColor {
    color: #FFFFFF;
    width: 1.2rem;
    height: 1.2rem;
}

.optionsBgColor{
    background: transparent!important;
    color: #FFFFFF!important;
    text-align: left;
}
.optionsBgColor option{
    background: #1E2022;
    color: #FFFFFF;
}