.right_main {
    width: 100%;
    background-color: transparent;
    color: #ffffff;
    padding: 0 15px;
}

.heading {
    font-weight: 600;
    font-size: 30px;
    margin: 16px 0 0px 0;
}

.superAdmin_heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
}

.dispatchHeading {
    font-weight: 600;
    font-size: 30px;
    margin: 6px 0 0px 0;
}

.dashboard_header {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;
    line-height: 1.5rem;
    justify-content: space-between;
}

.dispatchDashboard_header {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0;
    /* line-height: 1.5rem; */
    justify-content: space-between;
    align-items: center;
}

.dealer_search_div {
    position: relative;
    width: 20%;
}

.dealer_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 30px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 100%;
    min-width: 270px;
}

.dealer_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
}

.dealer_header_right {
    display: flex;
    flex-direction: row;
}

.dealer_Sort_By {
    padding-right: 30px;
}

.dealer_sortBy_txt {
    font-size: 20px;
    padding-right: 12px;
}

.dealer_sortBy_select {
    border: 1px solid #ffffff;
    height: 49px;
    width: 234px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 12px;
}

.dashboard_sortBy_select option {
    color: #000000;
    background-color: #ffffff !important;
}

.dashboard_header_select_days {
    max-height: 49px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 410px;
    justify-content: center;
}

.dashboard_header_select_week {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 310px;
    justify-content: center;
}

.recipt_tabs_div {
    height: 50px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    min-width: 310px;
    justify-content: center;
    padding: 0 6px;
}

.dashboard_days_select {
    width: 100px;
    text-align: center;
    border-radius: 25px;
    padding: 10px 0px;
    cursor: pointer;
}

.dashboard_select_overall {
    margin: auto;
    height: 46px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    align-items: center;
    width: 216px;
    justify-content: center;
}

.dashboard_overall_select {
    width: 102px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;

}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
}

.dashboard_main_div {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 156px);
}

.dashboard_main_div::-webkit-scrollbar {
    display: none;
}

.dashboard_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 30px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 100%;
    /* min-width: 270px; */
}

.dashboard_search_div {
    position: relative;
    width: 16%;
}

.dashboard_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
}

.dashboard_Sort_By {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard_sortBy_txt {
    font-size: 16px;
    padding-right: 8px;
}

.dashboard_sortBy_select {
    border: 2px solid #1E2022;
    height: 44px;
    width: 100px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 2px;
    border-radius: 6px;
    text-align: left;
    font-size: 13px;
}

.dashboard_sortBy_select option {
    text-align: left;
    background: #1E2022 !important;
    color: #FFFFFF;
}

.dashboard_sortBy_color {
    color: #000000;
    background-color: #ffffff !important;
}

.statusCard_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.statusCard {
      box-sizing: border-box;
    width: 10.6%;
    height: 120px;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.packed_active {
    border: 3px solid #FFC776;
}

.cancel_status {
    border: 3px solid #FFFFFF;
}

.inProgress_active {
    border: 3px solid #DDE100;
}

.Dispatches_active {
    border: 3px solid #1FFC33;
}

.danger_active {
    border: 3px solid #F32D3C;
}
.Pending_active {
    border: 3px solid #F32D3C;
}

.newOrder_active {
    border: 3px solid #24ABF8;
}

.orange_active {
    border: 3px solid #F56221;
}

.statusNew {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #24ABF8;
}

.statusAll {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}

.statusYellow {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #DDE100;
}

.statusWhite {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
}

.statusOrange {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F56221;
}

.statusRed {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #F32D3C;
}

.statusBlue {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFC776;
}

.statusGreen {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #1FFC33;
}

.statusTxt {
       font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: center;
    max-width: 96px;
}

.scroll {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 260px);
    min-height: calc(100vh - 260px);
}

.scroll::-webkit-scrollbar {
    display: none;
}

.scrollTable {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 260px);
    min-height: calc(100vh - 260px);
}

.scrollTable::-webkit-scrollbar {
    display: none;
}

table.itemTable {
    caption-side: bottom;
    /* border-collapse: unset; */
    border-spacing: 0 10px;
    width: 100% !important;
}

tr.item {
    border-color: #000;
    border-style: solid;
    border-width: 10px 0;
    line-height: 3 !important;
    background-color: #1E2022;
    font-weight: 300;
    font-size: 16px;
    padding: 20px;
    text-align: left;
    cursor: pointer;
}

.item td,
th {
    padding: 0 10px;
    border-color: #1E2022;
    text-align: left;
}

table.itemTable {
    caption-side: bottom;
    /* border-collapse: unset; */
    border-spacing: 0 10px;
    width: 100% !important;
}

.item td:first-child,
th:first-child {
    border-radius: 7px 0 0 7px;
    text-align: left;
}

.item td:last-child,
th:last-child {
    border-radius: 0 7px 7px 0;
}

.table_txt_green {
    color: #05FF00;
    font-weight: 600;
}

.table_txt_orange {
    color: #FFC776;
    font-weight: 600;
}

.table_txt_yellow {
    color: #DDE100;
    font-weight: 600;
}

.table_txt_blue {
    color: #2196F3;
    font-weight: 600;
}

.table_txt_red {
    color: #FF0D0D;
    font-weight: 600;
}

.actionPending_div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 80px 0 0 0;
    min-height: 480px;
}

.actionPending {
    width: 20%;
    box-sizing: border-box;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    min-height: 480px;
    position: relative;
}

.InactiveDealers {
    box-sizing: border-box;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    width: 80%;
    margin-left: 40px;
}

.actionPending_heading {
    border-bottom: 1px solid #4D4D4D;
    text-align: center;
    line-height: 60px;
    font-size: 22px;
}

.takeAction {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    bottom: 0;
    height: 100%;
    padding-bottom: 24px;
    position: absolute;
}

.InactiveDealers_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px
}

.InactiveIcon_main {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.inactiveIcon_div {
    border: 1px solid #4D4D4D;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 32px;
}

.inactiveIcon {
    width: 30px;
    height: 30px;
}

.whatsappIcon {
    width: 40px;
    height: 40px;
}

.inactive_profile_details {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 30px 0;
}

.profile_details {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    padding-bottom: 4px;
    font-size: 14px;
    min-width: 220px;
}

.inactive_dealer_img {
    margin: 0 10px;
}

.selectAll {
    float: right;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 4px;
    padding-right: 30px;
}

.textarea_css {
    background: #18191B;
    border-radius: 6.62606px;
    color: #FFFFFF;
    width: 100%;
    margin: 24px 0;
    border: 0;
    outline: none;
    padding: 10px;
    resize: none;
}

.progressBar_center {
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shortBy_search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 6px 0;
}

.adminHeight {
    overflow-y: scroll;
    max-height: calc(100vh - 416px);
    min-height: calc(100vh - 416px);
}

.adminHeight::-webkit-scrollbar {
    display: none;
}

.noData {
    width: 100%;
    max-height: 528px;
    min-height: 528px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dispatchOrders_height {
    overflow-y: scroll;
    max-height: 528px;
    min-height: 528px;
}

.dispatchOrders_height::-webkit-scrollbar {
    display: none;
}

.celender_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.startEndDate {
    height: 40px;
    width: 130px;
    border-radius: 6px;
    border: none;
    outline: none;
    text-align: center;
    border: 1px solid #3A3A3A;
    background: #000;
    color: #FFFFFF;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.startEndDate_div {
    margin-left: 40px;
}

.reload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-right: 40px;
}

.reload_txt {
    font-size: 12px;
    line-height: 14px;
}

.loadingOrders_dflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
}


.status_count_loading {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: #FFFFFF;
}

.statusTxt {
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-transform: capitalize;
}

.space_evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
}

.superAdmin_height {
    overflow-y: scroll;
    max-height: calc(100vh - 150px);
    /* min-height: 528px; */
}

.superAdmin_height::-webkit-scrollbar {
    display: none;
}

.tableIcons_center {
    text-align: center !important;
}

.table_status {
    color: yellow;
    border: 1px solid yellow;
    text-align: center;
    height: 32px;
    max-width: 80px;
    line-height: 28px;
    border-radius: 16px;
}

.table_status_pending {
    color: yellow;
    border: 1px solid yellow;
    text-align: center;
    height: 32px;
    max-width: 80px;
    line-height: 28px;
    border-radius: 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    min-height: 1.5em !important;
}

.table_status_green {
    color: #1FFC33;
    border: 1px solid #1FFC33;
    text-align: center;
    height: 32px;
    max-width: 80px;
    line-height: 28px;
    border-radius: 16px;
}

.table_status_red {
    color: #F32D3C;
    border: 1px solid #F32D3C;
    text-align: center;
    height: 32px;
    max-width: 80px;
    line-height: 28px;
    border-radius: 16px;
}

.table_heading_div {
    height: 50px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    border: 0.5px solid #3A3A3A;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    padding-left: 20px;
    /* padding: 0 20px; */
}

.table_heading {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    text-align: left;
}

.table_heading_mid {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    text-align: left;
}

.table_heading_shot {
    width: 64px;
    min-width: 64px;
    max-width: 64px;
    text-align: left;
}

.recipt_scrollTable {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 490px);
    min-height: calc(100vh - 490px);
}

.recipt_scrollTable::-webkit-scrollbar {
    display: none;
}

.table_content_div {
    min-height: 50px;
    background-color: #1E2022;
    border-radius: 6px;
    cursor: pointer;
    margin: 10px 0;
}

.table_content_subdiv {
    min-height: 50px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    color: #FFFFFF;
    border-radius: 6px;
    /* margin: 10px 0; */
    /* padding: 0 20px; */
    padding-left: 20px;
    cursor: pointer;
    position: relative;
}

.dropdown_content_div {
    padding: 0 108px;
}

.table_txt {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    text-align: left;
}

.table_txt_mid {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    text-align: left;
}

.table_txt_shot {
    width: 64px;
    min-width: 64px;
    max-width: 64px;
    text-align: left;
}

.dropdown_details_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}

.dropdown_details_div b {
    font-weight: 600;
}

.dropdown_reason {
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.1em;
    color: #1FFC33;
    padding-top: 6px;
    padding-bottom: 12px;
}

.ordersName_truncate {
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-transform: capitalize;
    max-width: 220px;
    position: relative;
    /* text-align: center; */
}

.showOverlay {
    position: absolute;
    background-color: #FFFFFF;
    width: 100px;
    right: 0;
    margin-right: 38px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    border-radius: 3px;
    margin-top: 8px;
}

.showOverlay span {
    line-height: 24px;
    padding-left: 6px;
    font-weight: 400;
    font-size: 14px;
    margin: 1px 2px;
    border-radius: 4px;
}

.showOverlay span:hover {
    background-color: #1E2022;
    color: #FFFFFF;
    font-weight: 600;
}

.text_center {
    text-align: center;
}

.massageModal_btn {
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin: auto;
    padding-bottom: 30px;
}

.message {
    text-align: center;
    margin: 16px 0 0 0;
    font-size: 24px;
}

.modal_backdrop {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: right;
    align-items: center;
    margin: auto;
    z-index: 1090;
    min-height: 100vh;
}

.modal_content {
    overflow-y: scroll;
    background-color: #FFFFFF;
    width: 100px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    border-radius: 3px;
    margin-right: 40px;
    /* z-index: 999; */
}

.modal_content::-webkit-scrollbar {
    display: none;
}

.modal_content span {
    line-height: 24px;
    padding-left: 6px;
    font-weight: 400;
    font-size: 14px;
    margin: 1px 2px;
    border-radius: 4px;
}

.modal_content span:hover {
    background-color: #1E2022;
    color: #FFFFFF;
    font-weight: 600;
}

/* @media only screen and (max-width: 599px) {
    .modal_content {
        width: 100%;
        padding: 20px;
        position: absolute;
        bottom: 0;
    }
} */

.warningInput {
    box-sizing: border-box;
    width: 88%;
    height: 48px;
    border: 0.5px solid #F3F3F3;
    border-radius: 4px;
    background-color: #1E2022;
    color: #FFFFFF;
    padding: 0 6px;
    text-align: center;
    font-size: 20px;
    margin-top: 16px;
}

.warnigModal_btn {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 24px auto 10px;
}

.ReciptModalHeader {
    position: absolute;
}

.reciptModal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    height: 100%;
}

.reciptModal_content {
    width: 599px;
    overflow-y: scroll;
    min-height: 200px;
    background-color: #1E2022;
    color: #FFFFFF;
    border-radius: 12px;
}

.reciptModal_content::-webkit-scrollbar {
    display: none;
}

.text_center {
    text-align: center;
}

.reciptHeader_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.reciptHeader_detail {}

.recipt_tableDiv {
    min-width: 200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.recipt_table_cell {
    min-width: 50%;
    text-align: center;
    border: 1px solid #FFFFFF;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 18px;
}

.reciptHeading {
    font-weight: 600;
    /* padding-bottom: 40px; */
}

.reciptContentDiv {
    padding: 0 40px;
}

.recipt_selectDiv {
    display: flex;
}

.recipt_selectDiv_Account {
    display: flex;
    margin: 16px 0;
}

.reciptMethod_select {
    box-sizing: border-box;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    font-size: 16px;
    padding: 0 6px;
}

.reciptMethod_select option {
    background-color: #1E2022;
    text-align: left;
}

.method_txt {
    /* margin-right: 40px; */
    font-size: 20px;
    line-height: 40px;
    min-width: 120px;
}

.reciptAmount_txt {
    margin: 18px 0;
    font-size: 18px;
}

.reciptImage {
    height: 100%;
    max-height: 224px;
    min-height: 224px;
}

.Image_div {
    border: 2.685px dashed #FFFFFF;
    padding: 12px;
    max-width: 350px;
    min-width: 350px;
    max-height: 250px;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.viewRecipt {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #1FFC33;
    margin-bottom: 0;
    padding-left: 84px;
}

.reciptImage_delete {
    right: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #F93E3E;
    /* border: 3px solid #00000038; */
    border-radius: 6px;
    padding: 10px 4px 0px 4px;
    line-height: 20px;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
}

.reciptImage_div {
    display: flex;
    justify-content: center;
    /* align-items: flex-start; */
    margin-top: 12px;
    position: relative;
}

.recipt_inputField {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 4px;
    margin: 32px 0 12px 0;
}

.recipt_inputField_ref {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 4px;
    margin-top: 16px;
}

.recipt_btn {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 16px auto 24px;
}

.recipt_payNow {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    line-height: 28px;
}

.reciptPositionAbsolute {
    z-index: 999;
    /* position: absolute; */
}

.recipt_AmountDiv {
    margin: 20px 0;
}

.reciptAmount_input {
    background-color: transparent;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #FFFFFF;
    outline: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    float: right;
    min-width: 400px;
}

.amount_txt {
    font-size: 20px;
    line-height: 40px;
    min-width: 120px;
}

.dragAndDrop_div {
    height: 224px;
    position: absolute;
    width: 516px;
    background-color: transparent;
    z-index: 1;
    /* margin: 16px 0; */
}

.dragImage_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    padding: 28px 0 0px 0;
    /* margin: 12px 0; */
}

.drag_txt {
    font: 600;
    font-size: 20px;
    width: 56%;
    text-align: center;
}

.border_OR_div {
    border: 1px solid #FFFFFF;
    width: 80%;
    margin: 0;
}

.OR_css {
    position: absolute;
    margin-top: 16px;
    background: #1E2022;
    padding: 0 20px;
    font-size: 20px;
}

.browseFiles_btn {
    border: 1px solid #FFFFFF;
    margin: 20px auto;
    border-radius: 6px;
    background: transparent;
    /* border: none; */
    outline: none;
    line-height: 44px;
    width: 200px;
    color: #FFFFFF;
    font-weight: 600;
    cursor: pointer;
}

.packing_image {
    background: transparent;
}

.methodAndAmount {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.method_selectDiv {
    width: 50%;
    margin-right: 5px;
}

.method {
    font-size: 20px;
    display: flex;
}

.refund_width {
    width: 88%;
    margin: auto;
}

.methodSelect {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: left;
    font-size: 16px;
    color: #FFFFFF;
}

.amount_input {
    width: 100%;
    background-color: transparent;
    height: 42px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 18px;
    color: #FFFFFF;
}

.methodSelect option {
    background-color: #1E2022;
}

.submethod_txt {
    font-size: 20px;
}

.cancel_receipt_btn {
    font-size: 14px;
    font-weight: 500;
}

/* ----------------------------------DispatchDasboard-Mobile--------------------------------------------- */

.main_container {
    background-color: #fff;
    width: 100%;
    height: 100vh;
}