.ml30 {
  margin-left: 30px;
}

.bg_dark {
  background-color: #000;
  color: #fff;
}

.bg_grey {
  background-color: #1E2022;
  color: #fff;
}

.bg_white {
  background-color: #fff;
  color: #000;
}

.bg_light {
  background-color: #E3E7EF;
  color: #18191B;
}

.mb5 {
  margin-bottom: 5px;
}

.mt5 {
  margin-top: 5px;
}

.clr_Blue {
  color: #24ABF8;
}

.mouse {
  cursor: pointer;
}

.position_relative {
  position: relative;
}

.marketingMain {
  margin: 20px;
  width: 100%;
}
.marketingMain h2{
  margin-top: 0;
}
.marketing {
  background-color: #1E2022;
  padding: 12px 12px 2px 12px;
  border-radius: 12px;
  padding: 20px;
}

.marketingRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
}



.search {
  /* width: 100%; */
  height: 30px;
  border-radius: 20px;
  border: 1px solid #1E2022;
  outline: none;
  position: relative;
}

.search_input {
  border-radius: 50px;
  height: 40px;
  padding-left: 30px;
  padding-right: 10px;
  background-color: transparent;
  border: 1px solid white;
  color: #ffffff;
  outline-offset: 0px !important;
  outline: none;
  width: 259px;
}

.searchIcon {
  position: absolute;
  left: 8px;
  top: 10px;
}

.sortSelect {
  background-color: #1E2022;
  border-radius: 5px;
  margin-left: 10px;
  padding: 10px;
  outline: none;
}
.marketingCol10 {
  flex: 0 0 auto;
  width: 83.33333333%;
  display: flex;
  margin-bottom: 20px;
}
.marketingCol {
  flex: 0 0 auto;
  /* width: 75%; */
  display: flex;
  margin-bottom: 20px;
}

.marketingCol2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.marketingCol3 {
  flex: 0 0 auto;
  width: 33.333333%;
}

.switchBtn {
  background: #fff;
  border-radius: 30px;
}

.switchBtnActive {
  width: 50%;
  height: 40px;
  border-radius: 30px;
  border: 0px;
  cursor: pointer;
}

.marketingScroll {
  height: 706px;
  max-height: 706px;
  min-height: 706px;
  overflow-y: scroll;
}

.tableCss {
  /* text-align: center; */
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 0.5px solid #3A3A3A !important;
  position: relative;
}

.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.col {
  flex: 1 0;
}

.col1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.priority_1 {
  color: red;
  border: 1px solid;
  width: fit-content;
  border-radius: 25px;
  padding: 8px;
}

.priority_2 {
  color: #05FF00;
  border: 1px solid;
  width: fit-content;
  border-radius: 25px;
  padding: 8px;
}

.priority_3 {
  color: #FF9700;
  border: 1px solid;
  width: fit-content;
  border-radius: 25px;
  padding: 8px;
}

.marketingOrder_lead_div {
  position: absolute;
  top: 15px;
  right: 1%;
}

.marketingOrder_lead {
  cursor: pointer;
  padding-right: 16px;
}

.threeDot {
  cursor: pointer;
  font-size: 26px;
  margin-top: 5px;
}

.threeDots_div_position {
  position: absolute;
  right: 0;
  top: 34px;
  z-index: 1060;
}

.threeDotBP {
  padding: 5px;
  border: 0.5px solid #3A3A3A;
  cursor: pointer;
}

.threeDotBL {
  border-top: 1px solid #18191B;
  background-color: #fff;
  color: #F93E3E;
}

.threeDotBB {
  border-top: 1px solid #fff;
  background-color: #000;
  color: #F93E3E;
}

.iconAdd {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: center;
}

/* ----------------------------------------------CreateContactModal------------------------------------------------------------ */
.custom_modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
}

.modal_content_white {
  background-color: #F7F8FA;
  color: #1E2022;
  width: 50%;
  max-width: 850px;
  height: 480px;
  margin: auto;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
}

.modal_content {
  background-color: #1E2022;
  color: #fff;
  width: 50%;
  max-width: 850px;
  min-height: 500px;
  margin: auto;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.text_center {
  text-align: center;
}

.fs_30 {
  font-size: 30px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.col_5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.offset_1 {
  margin-left: 5.33333333%;
}

.text_start {
  text-align: start;
}

.mt_10 {
  margin-top: 10px;
}

.text_Box_Dark {
  width: 100%;
  border: 0.826924px solid #3A3A3A;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 5px;
  background-color: transparent;
  padding: 10px;
  color: #3A3A3A;
}


.text_Box_red {
  width: 100%;
  border: 0.826924px solid red;
  outline: none;
  outline-color: red;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 5px;
  background-color: transparent;
  padding: 10px;
  color: #FFFFFF;
}

.text_Box {
  width: 100%;
  border: 0.826924px solid #F3F3F3;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 5px;
  background-color: transparent;
  padding: 10px;
  color: #FFFFFF;
  text-decoration: none;
}

.marketing_mobile_alert {
  font-size: 14px;
  color: red;
  margin: 0px;
  text-align: left;
}

.m_20 {
  margin: 20px;
}

.dangerBtn {
  border: 1px solid #F93E3E;
  background-color: transparent;
  color: #F93E3E;
  width: 155px;
  padding: 10px 0;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.greenBtn {
  border: 1px solid #1FFC33;
  background-color: #1FFC33;
  width: 155px;
  padding: 10px 0;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.ms_15p {
  margin-left: 15%;
}

.border_text_area_dark {
  width: 100%;
  height: 45px;
  border: 0.826924px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px;
  margin-top: 5px;
  background-color: transparent !important;
  color: #FFFFFF !important;
}

.h_36 {
  height: 36px;
}

.mt_40 {
  margin-top: 40px;
}

.bg_white_gray_Light {
  background-color: #F7F8FA;
  color: #18191B;
}

.leadLostModal {
  width: 50%;
  max-width: 517px;
  height: 240px;
  margin: auto;
  padding: 20px;
  border: 1px solid black;
  border-radius: 10px;
}

.fs_24 {
  font-size: 24px;
}

.text_danger {
  color: #F93E3E;
}

.fs_20 {
  font-size: 20px;
}

.mt_20 {
  margin-top: 20px;
}

.ms_10 {
  margin-left: 10px;
}

.mt_5 {
  margin-top: 5px;
}

.h_65 {
  height: 65px;
}

.br_30 {
  border-radius: 30px !important;
}

.text_success {
  color: #05FF00 !important;
}

.mob{
  display: flex;
}
.addMob {
  width: auto;
  border: 0.826924px solid #05FF00;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 5px;
  background-color: transparent;
  padding: 10px;
  color: #05FF00;
  text-decoration: none;
  margin-left: 5px;
  cursor: pointer;
}
.removeMob {
  width: auto;
  border: 0.826924px solid #F93E3E;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 5px;
  background-color: transparent;
  padding: 10px;
  color: #F93E3E;
  text-decoration: none;
  margin-left: 5px;
  cursor: pointer;
}