.modal_content {
    width: 768px;
    overflow-y: scroll;
    min-height: 200px;
    background-color: #fff;
    color: #000;
    padding: 10px 10px 10px 10px;
}

.modal_content::-webkit-scrollbar {
    display: none;
}

.modal_backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1090;
    height: 100%;
}

.addDealer_heading {
    font-size: 20px;
    font-weight: 600;
}

.AddDealer_input {
    width: 100%;
    height: 32px;
    outline: none;
    border: none;
    background: #F3F6F8;
    border-radius: 3px;
    padding-left: 4px;
    font-size: 16px;
}

.order_lebel {
    font-size: 14px;
    margin-top: 6px;
}

.addDealer_select {
    color: #000;
    background: #F3F6F8;
    width: 100%;
    height: 36px;
    outline: none;
    border-radius: 3px;
    margin: 0;
    text-align: left;
    /* font-weight: 600; */
}

.order_btn {
    border: 1px solid #000000;
    outline: none;
    background: #fff;
    border-radius: 3px;
    width: 50%;
    height: 40px;
    margin: 0 10px;
}

.order_btn1 {
    border: 1px solid #000000;
    outline: none;
    background: #000;
    border-radius: 3px;
    color: #ffff;
    width: 50%;
    height: 40px;
    margin: 0 10px;
}

.orders_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: 13px;
}

.mobileCheckbox {
    width: 14px;
    height: 14px;
}

.text_center {
    text-align: center;
}