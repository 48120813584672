.mob_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 6px;
    margin-bottom: 0;
}

.main_heading {
    font-weight: 500;
    font-size: 20px;
    color: #3A3A3A;
    text-decoration-line: underline;

}

.text {
    font-size: 10px;
}

.box {
    /* height: 187.09px; */
    box-shadow: 0px 0px 1.74558px 0.872789px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    margin: 10px;
}

.outSidebox {
    /* height: 187.09px; */
    box-shadow: 0px 0px 1.74558px 0.872789px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    margin: 10px;
    padding-bottom: 6px;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    padding-top: 0;
}

.productdiv {
    width: 75px;
    height: 75px;
    background: #FFFFFF;
    border: 0.5px solid #DFDFDF;
    border-radius: 6px;
}

.imagediv {
    width: 80px;
    height: 80px;
    border: 1px solid lightgrey;
    margin-left: 5px;
}

.outSide_imagediv {
    width: 80px;
    max-height: 80px;
    border: 1px solid lightgrey;
    margin-left: 5px;
}

.product_img {
    width: 100%;
    height: -webkit-fill-available;
    padding: 2px;
}

.product {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #3A3A3A;
    padding: 4px 0;
    margin: 0;
    width: 198px;
    padding-bottom: 0;
}

.status {
    font-size: 10px;
    /* line-height: 13px; */
    text-align: end;
    /* padding: 0px 10px 0 0; */
    /* margin-bottom: -25px; */
    color: rgb(31, 113, 244);
    font-weight: 600;
}

.outSidebox_date {
    font-size: 11px;
    text-align: end;
    color: #000;
    line-height: 20px;
    font-weight: bold !important;
}

.status_active[type="radio"] {
    width: 12px;
    height: 12px;
}

.status_div2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* 
.status1 {
    font-size: 10px;
    line-height: 13px;
    text-align: end;
    padding: 10px 10px 0 0;
    margin-bottom: -25px;
    color: red;
}

.status1 {
    font-size: 10px;
    line-height: 13px;
    text-align: end;
    padding: 10px 10px 0 0;
    margin-bottom: -25px;
}

.status2 {
    font-size: 10px;
    line-height: 13px;
    text-align: end;
    padding: 10px 10px 0 0;
} */

.coll_box {
    width: 60px;
    /* height: 30.52px; */
    background: #F3F6F8;
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    text-align: center;
    margin-bottom: 6px;
}

.outside_box {
    width: 80px;
    /* height: 30.52px; */
    background: #F3F6F8;
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    text-align: center;
    margin-bottom: 6px;
    color: #000;
}

.outside_description {
    width: 100%;
    height: 28px;
    background: #F3F6F8;
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    text-align: center;
}

.colltext {
    font-size: 12px;
    color: #3d3d3d;
}

.outside_text {
    font-size: 13px;
    color: #3d3d3d;
    width: 80px;
    font-weight: 600;
}

.colltext_div {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.05em;
    color: #ADADAD;
    min-width: 100px;
}


/*------------------------inventory collectionmob------------------- */
.box_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}

.flex_coll {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px 5px 5px;
}

.statusActive {
    font-size: 10px;
    line-height: 13px;
    color: rgb(31, 113, 244);
}

.statusdelete {
    font-size: 10px;
    line-height: 13px;
    color: red;
}

.coll_boxcoll {
    width: 72px;
    /* height: 30.52px; */
    background: #F3F6F8;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
    border: none;
    outline: none;
    text-align: center;
}

.boxcoll {
    width: 72px;
    min-width: 70px;
    background: #F3F6F8;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
    border: none;
    outline: none;
    text-align: center;
}

.outSide_boxcoll {
    min-width: 90px;
    background: #F3F6F8;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
    border: none;
    outline: none;
    text-align: center;
}

.colltitem_text {
    min-width: 75px;
    font-size: 12px;
    color: #3d3d3d;
}

/* --------------------collection Info Mob----------------------------- */
.statusCard_div {
    display: grid;
    grid-template-columns: auto auto auto;
}

.statuscard1 {
    padding: 10px;
}

.statusCard {
    min-width: 60px;
    min-height: 64px;
    background: #FFFFFF;
    border: 0.407232px solid #D3D3D3;
    border-radius: 3.15007px;
    cursor: pointer;
    font-size: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.statusNew {
    color: #24ABF8;
    font-weight: 500;
    font-size: 12px;
}

.statusAll {
    color: #F56221;
    font-weight: 500;
    font-size: 12px;

}

.statusYellow {
    color: #DDE100;
    font-weight: 500;
    font-size: 12px;

}

.statusWhite {
    color: #000;
    font-weight: 500;
    font-size: 12px;
}

.statusOrange {
    color: #F56221;
    font-weight: 500;
    font-size: 12px;
}

.statusRed {
    color: #F32D3C;
    font-weight: 500;
    font-size: 12px;
}

.statusBlue {
    color: #FFC776;
    font-weight: 500;
    font-size: 12px;
}

.statusGreen {
    color: #1FFC33;
    font-weight: 500;
    font-size: 12px;
}

.ItemsCollection_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 10px;
}

.seacrh_div {
    width: 150px;
    position: relative;
}

.seacrh_inputField {
    width: 150px;
    height: 31.37px;
    background: #FFFFFF;
    border: 0.5px solid #D3D3D3;
    border-radius: 50px;
    font-size: 12px;
    padding-left: 10px;
    outline: none;
}

.search_Icon {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 31.37px;
    width: 20px;
    padding-right: 4%;
    cursor: pointer;

}
.search_Icon_filter {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 31.37px;
    width: 20px;
    padding-right: 4%;
    cursor: pointer;
}

.collectionbtn {
    font-weight: 500;
    font-size: 12px;
    width: 124.25px;
    height: 31.37px;
    border: 0.5px solid #D3D3D3;
    border-radius: 3px;
}

.main_heading1 {
    font-weight: 500;
    font-size: 20px;
    color: #3A3A3A;
    text-decoration-line: underline;
    padding-left: 10px;
    margin: 10px 0;
}

.item_collection {
    font-weight: 500;
    font-size: 20px;
    color: #3A3A3A;
    padding-left: 10px;
    margin: 10px 0;
}

/* -----------------modal------------------ */
.details_txt {
    font-weight: 500;
    font-size: 14px;
}

.selectDropdown {
    height: 40px;
    background: #F3F6F8;
    border-radius: 3px;
    color: #000;
    text-align: left;
}

.inputField {
    background: #F3F6F8;
    border-radius: 3px;
    height: 40px;
    background: #F3F6F8;
    width: 100%;
    outline: none;
    border: none;
}

.details_div {
    margin: 20px 10px;
}

.collection_button {
    width: 159px;
    height: 37.32px;
    border-radius: 5px;
    background: #000000;
    outline: none;
    border: none;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.collection_flex {
    display: flex;
    justify-content: space-between;
    margin: 10px 10px 20px 10px;
}

.dot_flex {
    display: flex;
    justify-content: center;
    margin-top: -50px;
}

.color {
    color: #ADADAD;
}

.massageModal_btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.outsourcedProduct {
    display: flex;
    /* justify-content: space-between; */
    padding: 0 10px 5px 10px;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.05em;
    color: #3a3a3a;
}

.outsideProduct_description {
    /* display: flex; */
    /* justify-content: space-between; */
    padding: 0 10px 5px 10px;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.05em;
    color: #3a3a3a;
}

.outsourcetext {
    min-width: 140px;
}

.outsourcedStatus {
    display: flex;
    /* justify-content: space-around; */
    width: 100%;
}

.margin_left {
    margin-left: 20px;
}

.edit_Icon {
    width: 16px;
    height: 16px;
}

.inputFields_div {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
}

.inputField_name {
    box-sizing: border-box;
    background: #F3F6F8;
    border-radius: 3px;
    border: none;
    line-height: 32px;
    padding: 0 5px;
    outline: none;
}

.inputField_item_name {
    box-sizing: border-box;
    background: #F3F6F8;
    border-radius: 3px;
    border: none;
    line-height: 32px;
    padding: 0 5px;
    width: 100%;
    outline: none;
}

.inputFields_heading {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
}

.uploadImage_div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: auto;
    margin-top: 16px;
    align-items: center;
}

.uploadImage_btn {
    border: 1px solid #1FFC33;
    background-color: transparent;
    color: #000;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.modalItem_Image {
    width: 76px;
    height: 36px;
    object-fit: cover;
}

.demoImage_div {
    width: 86px;
    height: 42px;
    border: 1px solid gray;
    background-color: transparent;
    padding: 4px;
    border-radius: 2px;
}

.demoImage_bgc {
    background-color: #fff !important;
    width: 76px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete_div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #F93E3E;
    text-align: center;
    width: 64px;
    min-height: 36px;
    padding: 4px 0;
    cursor: pointer;
    border: 2px solid #00000045;
    border-radius: 4px;
}

.modalButton_div {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 20px auto;

}

.add_btn_css {
    max-width: 110px;
    min-width: 110px;
    line-height: 20px;
}

.selectDropdown_filter {
    height: 32px;
    background: #F3F6F8;
    border-radius: 3px;
    color: #000;
    text-align: left;
    width: 120px;
    font-size: 11px;
}

.seacrh_inputField_filter {
    width: 120px;
    height: 31.37px;
    background: #FFFFFF;
    border: 0.5px solid #D3D3D3;
    border-radius: 50px;
    font-size: 12px;
    padding-left: 10px;
    outline: none;
}