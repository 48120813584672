.main_container {
    background-color: #fff;
    width: 100%;
    height: 100vh;
    /* padding: 10px; */
    /* margin-top: 50px; */
}

.tab_dropdown {
    border-right: 1px solid #D3D3D3;
    width: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding: 0 5px;
    text-transform: capitalize;
}

.dispatch_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #D3D3D3;
    color: #747474;
    border-radius: 5px;
    overflow: hidden;
    font-size: 10px;
    height: 30px;
    margin: 10px;
}

.dispatch_div1 {
    display: flex;
    align-items: center;
}

.dispatch_select {
    color: #000;
    font-size: 11px;
    padding: 0 5px;
}

/* .dispatch_select option {
    text-align: left;
    font-size: 11px;
} */

.date_select {
    border: none;
    outline: none;
    width: 76px;
    /* margin-right: 5px;  */
}


.date_margin_top {
    margin-top: 233px;
}

.statusCard_div {
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.statuscard1 {
    padding: 10px;
}

.statusCard {
    min-width: 60px;
    min-height: 64px;
    background: #FFFFFF;
    border: 0.407232px solid #D3D3D3;
    border-radius: 3.15007px;
    cursor: pointer;
    font-size: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* padding: 10px 0 */
}

.loadmoreIocon {
    text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
    .loadmoreIocon {
        animation: loadmoreIocon-spin infinite 1s linear;
    }
}

@keyframes loadmoreIocon-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.packed_active {
    border: 3px solid #FFC776;
}

.cancel_status {
    border: 3px solid #000;
}

.inProgress_active {
    border: 3px solid #DDE100;
}

.Dispatches_active {
    border: 3px solid #1FFC33;
}

.Pending_active {
    border: 3px solid #F32D3C;
}

.newOrder_active {
    border: 3px solid #24ABF8;
}

.orange_active {
    border: 3px solid #F56221;
}

.orders_status {
    font-weight: 600;
    font-size: 20px;
    line-height: 10px;
    position: absolute;
    right: 10px;
    top: 5px;
    margin-bottom: 10px;
}

.orders_status_filter {
    font-weight: 600;
    font-size: 20px;
}

.loadmore {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statusNew {
    color: #24ABF8;
}

.statusAll {
    color: #F56221;
    /* margin: 5px; */
}

.statusYellow {
    color: #DDE100;
    /* margin: 5px; */
}

.statusWhite {
    color: #000;
}

.statusOrange {
    color: #F56221;
}

.statusRed {
    color: #F32D3C;
}

.statusBlue {
    color: #FFC776;
}

.statusGreen {
    color: #1FFC33;
}

.statusTxt {
    font-weight: 500;
    font-size: 14px;
}

.dashboard_search {
    background: #FFFFFF;
    border: 0.5px solid #3A3A3A;
    border-radius: 50px;
    width: 100px;
    height: 25px;
    font-size: 11px;
    outline: none;
}

.dashboard_search_div {
    position: relative;
    /* width: 30%; */
}

.dashboard_search_icon {
    position: absolute;
    margin-left: -24px;
    margin-top: 3px;
    width: 15px;
    outline: none;
}

.icons_css {
    width: 30.16px;
    height: 30px;
    border: 0.5px solid #D9D9D9;
    border-radius: 16px;
    text-align: center;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    position: relative;
}

.orders_div {
    /* height: 125px; */
    background: #FFFFFF;
    border: 0.608031px solid #E5E5E5;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    align-items: center;
    margin: 10px 0;
    position: relative;
}

.ordes_img {
    height: 100%;
    min-height: 35px;
    max-height: 60px;
}

.order_detail {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    /* width: 72%; */
}

.orderDate {
    display: flex;
    justify-content: space-between;
    min-width: 184px;
}

.orders_text {
    font-size: 22px;
    padding: 0 10px;
    /* padding: 10px 0; */
}

.order_detail h2 {
    font-size: 16px;
    color: #3A3A3A;
    margin-bottom: -0;
}

.order_detail span {
    font-size: 12px;
    /* color: #3A3A3A; */
    line-height: 18px;

}

.dispatch_select h2 {
    font-size: 18px;
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 10px;
    /* margin-top: 10px; */
}

.days_active {
    background-color: #000;
    color: #fff;
    cursor: pointer;
}

.dashboard_days_select {
    margin: 10px;
    text-align: center;
    border-radius: 5px;
    padding: 12px 0px;
    cursor: pointer;
}

.largerCheckbox {
    width: 20px;
    height: 20px;
}

.dispatch_select1 {
    color: #484A4B;
    font-size: 11px;
    /* padding: 0 15px; */
}

.dispatch_select1 h2 {
    font-size: 18px;
    height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 10px;
}

.orders {
    margin: 0 10px;
}

.noData {
    width: 100%;
    max-height: 528px;
    min-height: 528px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* -----------------Short---------- */
.short_div {
    position: absolute;
    top: 35px;
    background: #fff;
    width: 100%;
    z-index: 1900;
    text-align-last: left;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 25px 50px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    max-width: 300px;
    right: 0;
}

.short_div h3 {
    margin-bottom: 15px;
}

.short_div h6 {
    margin: 2px 0;
    text-align: center;
    padding: 5px;
    cursor: pointer;
}

@media screen and (min-width:390px) {
    .dispatch_div {
        font-size: 12px;
        /* padding:10px 5px; */
    }

    .tab_dropdown {
        width: 80px;
    }

    .date_select {
        width: 87px;
    }

    .dashboard_search {
        width: 150px;
    }
}

@media screen and (max-width:390px) {
    .statusTxt {
        font-size: 10px;
    }
}