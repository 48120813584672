.main_container {
    color: #FFFFFF;
    width: 100%;
    padding: 0 10px;
}

.complain_header {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0;
    line-height: 1.5rem;
    justify-content: space-between;
    align-items: center;
}

.heading {
    font-weight: 500;
    font-size: 30px;
    margin: 0;
}

.complain_header_right {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.complain_search_div {
    position: relative;
    margin-right: 20px;
}

.dealer_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 30px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 100%;
    min-width: 220px;
}

.dealer_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
}

.complain_sortBy_txt {
    font-size: 20px;
    padding-right: 12px;
}

.complain_sortBy_select {
    border: 1px solid #ffffff;
    height: 49px;
    width: 234px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    padding-left: 12px;
}

.table_heading_div {
    height: 50px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    border: 0.5px solid #3A3A3A;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    padding: 0 10px 0 20px;
    position: relative;
}

.table_heading {
    min-width: 8%;
    text-align: left;
}

.table_heading_name {
    min-width: 16%;
    text-align: left;
}

.table_heading_msg {
    min-width: 24%;
    text-align: left;
}

.table_heading_threeDot {
    min-width: 4%;
    text-align: center;
}

.scrollTable {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 324px);
    min-height: calc(100vh - 324px);
    padding-top: 5px;
}

.scrollTable::-webkit-scrollbar {
    display: none;
}

.table_content_div {
    min-height: 50px;
    background-color: #1E2022;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    color: #FFFFFF;
    border-radius: 6px;
    margin: 10px 0;
    padding: 0 10px 0 20px;
    cursor: pointer;
    position: relative;
}

.table_txt {
    min-width: 8%;
    text-align: left;
}

.table_txt_name {
    min-width: 16%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 44px;
}

.table_txt_msg {
    min-width: 24%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 44px;
}

.table_txt_threeDot {
    min-width: 4%;
    text-align: center;
}

.table_txt_green {
    min-width: 16.66%;
    text-align: center;
    color: #29BB89;
}

.table_txt_yellow {
    min-width: 16.66%;
    text-align: center;
    color: #FFA32F;
}

.table_txt_red {
    min-width: 16.66%;
    text-align: center;
    color: red;
}

/* ---------------------------Create Template---------------------------------- */

.templateName_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    background-color: #1E2022;
    padding: 16px 10px;
    border-radius: 8px;
    position: relative;
}

.doneButton {
    width: 84px;
}

.template_guidelines {
    color: #2196F3;
    margin-right: 20px;
    /* text-decoration: underline; */
    cursor: pointer;
}

.template_change {
    color: #2196F3;
    /* margin-right: 20px; */
    /* text-decoration: underline; */
    cursor: pointer;
}

.templteName {
    background-color: transparent;
    border: 1px solid #ffffff;
    height: 40px;
    line-height: 40;
    border-radius: 5px;
    padding: 0 8px;
    margin-left: 20px;
    min-width: 400px;
    color: #fff;
}

.templateLeft {
    width: 16%;
    padding-right: 16px;
}

.templateCenter {
    width: 64%;
    background-color: #1E2022;
    border-radius: 8px;
    padding: 20px;
    margin-top: 12px;
    overflow-y: scroll;
    max-height: calc(100vh - 164px);
    min-height: calc(100vh - 222px);
}

.templateCenter::-webkit-scrollbar {
    display: none;
}

.templateRight {
    width: 20%;
    padding: 0 10px;
}

.headerType_select {
    margin-top: 6px;
    background: #000;
    width: 200px;
    height: 36px;
    border-radius: 5px;
    text-align: left;
    padding: 0 5px;
}

.language_select {
    margin-top: 4px;
    background: #000;
    width: 100%;
    height: 36px;
    border-radius: 5px;
    text-align: left;
    padding: 0 5px;
    border: 1px solid #ffffff;
    /* margin-top: 16px; */
}

.templateBody {
    background-color: #000000;
    width: 100%;
    border-radius: 6px;
    padding: 0 5px;
    color: #fff;
    text-transform: capitalize;
}

.templateBody_count {
    font-size: 14px;
    line-height: 14px;
    /* text-align: right; */
}

.templateFooter {
    background-color: #000000;
    width: 100%;
    border-radius: 4px;
    padding: 0 5px;
    border: 1px solid #ffffff;
    margin-bottom: 4px;
    height: 40px;
    color: #fff;
    text-transform: capitalize;
}

.templateButton {
    display: flex;
    background-color: #000000;
    width: 100%;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid #ffffff;
    margin-bottom: 16px;
    position: relative;
}

.addButton {
    width: 100px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.templateContent {
    /* margin-top: 16px; */
    padding: 16px;
    border-radius: 8px;
    background-color: #fff;
    width: 320px;
    min-height: 50px;
    margin: 16px auto;
    position: relative;
}

.mediadiv {
    background: #ccd0d5;
    width: 100%;
    height: 160px;
    border-radius: 8px;
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.darkBtn {
    border: 1px solid #BABABA;
    background-color: transparent;
    color: #BABABA;
    width: 155px;
    padding: 10px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

.header_radio_btn[type="radio"] {
    min-width: 16px;
    min-height: 16px;
}

.mediaOptions {
    margin: 0 40px 0 6px;
}

.CallToActionBtn {
    border: 1px solid #BABABA;
    background-color: #1E2022;
    ;
    color: #BABABA;
    width: 100%;
    padding: 10px 5px;
    text-align: left;
    border-radius: 5px;
    cursor: pointer;
}

.typeofAction {
    width: 18%;
    margin-right: 6px;
}

.btnTxt {
    width: 16%;
    margin-right: 6px;
}

.urltype {
    width: 10%;
    margin-right: 6px;
}

.websiteURL {
    width: 56%;
}

.removeBtn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 3px;
    cursor: pointer;
}

.txtEditor_btn {
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
}

.icon_css {
    margin: 0 10px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.emojis_list {
    position: absolute;
    width: 290px;
    margin-top: 32px;
    right: 0;
    top: 0;
}

.emojis_div {
    height: 270px;
    padding: 10px 0 0 10px;
    overflow-y: scroll;
    padding-bottom: 10px;
    color: #000;
}

.emojis_div ::-webkit-scrollbar {
    display: none;
}

.emojis_icon_css {
    font-size: 24px;
    cursor: pointer;
}


.reciptPositionAbsolute {
    z-index: 999;
    /* position: absolute; */
}

.modal_content {
    overflow-y: scroll;
    background-color: #FFFFFF;
    width: 116px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    border-radius: 3px;
    margin-right: 68px;
    /* z-index: 999; */
}

.modal_content::-webkit-scrollbar {
    display: none;
}

.modal_content span {
    line-height: 24px;
    padding-left: 6px;
    font-weight: 400;
    font-size: 14px;
    margin: 1px 2px;
    border-radius: 4px;
}

.modal_content span:hover {
    background-color: #1E2022;
    color: #FFFFFF;
    font-weight: 600;
}

.modal_backdrop {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: right;
    align-items: center;
    margin: auto;
    z-index: 1090;
    min-height: 100vh;
}

.modal_close_button {
    font-size: 1.4rem;
    font-weight: 700;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    background: transparent;
}

.dealerNameModal_btn {
    display: flex;
    width: 75%;
    justify-content: space-between;
    margin: auto;
}

.desc_text {
    font-size: 24px;
    margin: 20px 0 50px 0;
    text-align: center;
    width: 100%;
}

.message {
    text-align: center;
    margin: 10px 0 20px 0;
    font-size: 24px;
}

.input_delete {
    background: transparent;
    border: 1px solid #fff;
    height: 40px;
    border-radius: 4px;
    margin-bottom: 20px;
    display: flex;
    margin: auto;
    margin-bottom: 32px;
    color: #fff;
    font-size: 20px;
    max-width: 90px;
    line-height: 20px;
    text-align: center;
}

.input_Mobile_number {
    background: transparent;
    border: 1px solid #fff;
    height: 32px;
    border-radius: 3px;
    display: flex;
    color: #fff;
    font-size: 18px;
    max-width: 146px;
    line-height: 20px;
    text-align: left;
    padding: 0 5px;
    margin-left: 32px;
}

.templateName_alert {
    position: absolute;
    bottom: 0;
    margin-left: 132px;
    font-size: 13px;
    color: red;
}

.error {
    color: red;
    font-size: 12px;
}

.websiteLink {
    text-decoration: none;
    cursor: pointer;
}

.websiteLink:hover {
    color: indigo;
}

.pdfView {
 width: 304px;
    height: 160px;
    overflow: hidden;
    overflow-y: hidden;
    position: absolute;
    top: 15px;
    right: 0;
    z-index: 1;
}

.pdf_container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow-y: hidden;
}

.pdf_vertical {
    min-width: 15px;
    position: absolute;
    background-color: #fff;
    height: 162px;
    right: 0;
    z-index: 999;
    top: 14px;
}