.mainContainer {
    background-color: #1E2022;
    color: #fff;
    width: 100%;
    margin: 15px;
    border-radius: 10px;
    padding: 40px;
    padding-top: 16px;
    position: relative;
}

/* .bg_dark {
    background-color: #000000;
    color: #fff;
    width: 100%;
    padding: 0 14px;
} */

.voucher_maindiv {
    width: 100%;
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    padding: 20px 0 18px 0;
    margin: 16px 0;
    position: relative;
}

.packingHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.packingHeading h2 {
    margin: 0;
}

.backArrow {
    cursor: pointer;
    text-decoration: underline;
}

.voucherStatus {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding-right: 90px;
}

.status {
    color: #DDE100;
}

.status_blue {
    color: #2196F3;
}

.statuscolor1 {
    color: #FF0D0D;
}

.statuscolor3 {
    color: #05FF00;
}

.orderStatus_Txt {
    font-size: 15px;
    padding-bottom: 4px;
    font-weight: 600;
}

.packing_left {
    text-align: left;
}

.voucher_flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.voucherFlexDirection {
    display: flex;
    flex-direction: column;
}

.slipheading_div {
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
    margin: 0;
}

.sliptext {
    font-weight: 300;
    font-size: 16px;
    color: lightgray;
}

.sliptextGustin {
    font-weight: 300;
    font-size: 16px;
    color: lightgray;
    text-transform: uppercase;
}

.packingVoucher_left {
    min-width: 432px;
}

.slipDetailsTxt {
    font-weight: 300;
    font-size: 16px;
    line-height: 12px;
    color: lightgray;
    width: 50%;
    padding-top: 6px;
}

.DeliveryDetailsTxt {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: lightgray;
}

.voucher_flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.slipDetailsData {
    font-weight: 400;
    font-size: 16px;
    line-height: 10px;
    color: lightgray;
    width: 50%;
    padding-top: 6px;
}

.slipDetailsDataSelect {
    border: 1px solid;
    font-weight: 300;
    font-size: 16px;
    line-height: 10px;
    color: lightgray;
    width: 50%;
    padding-top: 6px;
    text-align: left;
}

.slipDetailsDataSelect option {
    background-color: #1E2022;
}

.deliveryPartner {
    border: 1px solid;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    color: lightgray;
    width: 50%;
    text-align: left;
    background: transparent;
    padding-left: 5px;
}

.packs_image {
    width: 100%;
    /* border: 1px solid #6E6F70; */
    padding: 6px;
    /* border-radius: 6px; */
    object-fit: cover;
    /* min-width: 362px; */
    min-height: 134px;
    /* margin-right: 20px; */
    cursor: pointer;
}

.item_image {
    width: 60px;
    border-radius: 4px;
    border: 1px solid #6E6F70;
min-height: 80px;
}

.item_card {
    border: 1px solid #6E6F70;
    width: 23%;
    border-radius: 5px;
    padding: 6px;
    margin-right: 22px;
    margin-top: 20px;
    min-width: 270px;
}

.main_pack_image {
    width: 100%;
    min-height: 116px;
    min-width: 250px;
}

.modal_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
}

.input {
    box-sizing: border-box;
    width: 50%;
    margin: 0 5px;
    height: 40px;
    outline: none;
    background: #1E2022;
    color: #fff;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    max-width: 283px;
    padding: 0 0 0 8px;
}

.inputcolor {
    width: 50%;
    margin: 0 5px;
    height: 30px;
    outline: none;
    background: #fff;
    color: #000;
    border: 0.5px solid #000;
    border-radius: 3px;
}

.modal_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
}

.uploadImage_div {
    width: 85%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: auto;
    margin-top: 32px;
    align-items: center;
}

.demoImage_div {
    width: 86px;
    height: 48px;
    border: 1px solid #ffffff;
    background-color: transparent;
    padding: 4px;
}

.modalItem_Image {
    width: 76px;
    height: 38px;
    object-fit: cover;
}

.uploadImage_btn {
    border: 1px solid #1FFC33;
    background-color: transparent;
    color: #FFFFFF;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    width: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.delete_div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #F93E3E;
    text-align: center;
    width: 64px;
    min-height: 36px;
    padding: 4px 0;
    cursor: pointer;
    border: 3px solid #00000045;
    border-radius: 8px;
}

.modalBtn_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 360px;
    margin: 32px auto 8px;
}

.item_pack_div {
    border: 1px solid #6E6F70;
    border-radius: 5px;
    min-width: 250px;
}

.button_div {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
}

.btn_flex {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.mainPack_delivery_image {
    display: flex;
    flex-wrap: wrap;
}

.pack_image_div {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 300px;
    max-width: 400px;
    margin-right: 32px;
}

.delivery_image {
    width: 100%;
    border: 1px solid #6E6F70;
    padding: 6px;
    border-radius: 6px;
    object-fit: cover;
    min-height: 134px;
    max-height: 134px;
    cursor: pointer;
}