.darkBtn {
    border: 1px solid #BABABA;
    background-color: transparent;
    color: #BABABA;
    width: 155px;
    padding: 10px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

.successBtn {
    min-width: 112px;
    background-color: transparent;
    border: 1px solid #1FFC33;
    border-radius: 5px;
    padding: 10px 0;
    color: #1FFC33;
    font-size: 13px;
    cursor: pointer;
}

.outBtnCSs {
    padding: 10px;
}

.greenBtn {
    border: 1px solid #1FFC33;
    background-color: #1FFC33;
    width: 155px;
    padding: 10px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

.dangerBtn {
    border: 1px solid #F93E3E !important;
    background-color: transparent;
    color: #F93E3E;
    min-width: 120px;
    padding: 10px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

.helpBtn {
    border: 0;
    outline: none;
    background: #1f73b7;
    display: flex;
    align-items: center;
    color: #fff;
    padding: 8px 15px;
    border-radius: 30px;
    font-weight: 600;
    position: absolute;
    right: 25px;
    bottom: 25px;
}

.helpBtn svg {
    color: #fff;
    margin-right: 5px;
}

.helpModal {
    height: 350px;
    width: 300px;
    background: #fff;
    /* color: #fff; */
    position: absolute;
    right: 25px;
    bottom: 25px;
    border-radius: 5px;
}

.helpModal body {
    padding: 10px;
    color: #000;
}

.helpModal body select {
    color: #000;
    border: 1px solid;
    font-size: 16px;
    margin-top: 10px;
    border-radius: 3px;
}

.helpModal body button {
    background: transparent;
    color: #07da19;
    border: 1px solid #07da19;
    border-radius: 5px;
    padding: 5px 20px;
}

.helpModal body h6 {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.helpModal body textarea {
    width: 100%;
    outline: none;
    margin-top: 10px;
}

.helpModalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1f73b7;
    color: #fff;
    padding: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.helpModalHeader h5 {
    margin: 0;
}