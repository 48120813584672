.menuItem_div {
    text-align: center;
    margin-top: 2px;
    width: 100%;
    padding: 14px 0;
    background-color: #1E2022;
    color: #ffffff;
    font-size: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.active_leftMenu {
    color: #24ABF8;
}

.leftMenu {
    display: flex;
    flex-direction: column;
    width: 66px;
    max-width: 66px;
    min-width: 66px;
    max-height: calc(100vh - 66px);
    overflow-y: scroll;
}

.leftMenu::-webkit-scrollbar {
    display: none;
}

.iconImage {
    display: block;
    margin: auto;
    width: 24px;
}