.main_div_dark {
    background-color: #000000;
    width: 100%;
    padding: 16px;
}

.main_container {
    background: #1E2022;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    padding: 0 50px;
    min-height: calc(100vh - 100px);
}

.orderHeading {
    font-style: normal;
    font-weight: 500;
    /* font-size: 20px; */
    letter-spacing: 0.08em;
    text-decoration: underline;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
    /* padding-top: 10px; */
    /* position: absolute;
    left: 50%;
    transform: translate(-50%, 0px); */
}

.devliveryAddress {
    width: 100%;
    text-align: center;
    border: 1px solid;
    font-size: 18px;
    font-weight: 600;
    /* margin-top: 12px; */
    background-color: transparent;
    padding: 5px;
    border-radius: 2px;
}

.tabs_div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dealer_header_select_days {
    height: 48px;
    font-size: 13px;
    color: black;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    align-items: center;
    min-width: 214px;
    justify-content: center;
    padding: 1px 5px;
}

.dealer_days_select {
    width: 102px;
    text-align: center;
    border-radius: 25px;
    padding: 8px 0px;
    cursor: pointer;
    color: gray;
}

.days_active {
    background-color: #E3E7EF;
    cursor: pointer;
    font-weight: bold;
    color: #000;
}

.d_flex {
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    margin-top: 8px;
}

.orderContent_div {
    width: 48%;
}

.orderInput_div {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    margin-top: 6px;
    position: relative;
}

.order_txt {
    line-height: 36px;
}
.product_warehouse_txt {
    width: 50%;
    display: flex;
    justify-content: space-between;
}

.item_warehouse_txt {
    line-height: 36px;
    min-width: 200px;
}
.product_txt {
    line-height: 36px;
    /* width: 20%; */
}

.productandwarehouse_name {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding-right: 8px;
}

.dealerInputField {
    box-sizing: border-box;
    width: 100%;
    background: transparent;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
    min-height: 36px;
    outline: none;
    padding-left: 5px;
}

.inputField {
    box-sizing: border-box;
    width: 65%;
    background: transparent;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
    min-height: 36px;
    outline: none;
}

.mx_10 {
    margin: 0 10px;
}

.dealerCreateNew {
    background: none;
    border: none;
    color: #fff;
    /* padding: 3px; */
    /* text-align: center; */
    cursor: pointer;
    text-decoration: underline;
    display: flex;
    justify-content: space-between;
}

.search_icon {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding-right: 10px;
    cursor: pointer;
}

.inputField_textArea {
    outline: none;
    box-sizing: border-box;
    width: 65%;
    background: transparent;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    height: 80px;
}

.voucherDate_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 65%;
}

.whereHouse_select_box {
    width: 65%;
    background-color: transparent;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 30px;
    font-size: 15px;
    font-weight: 400;
    min-height: 36px;
    padding: 0 4px;
    text-align: left;
}

.whereHouse_select_box:focus {
    outline: #1FFC33;
    border: 1px solid #1FFC33
}

.inputFocus:focus {
    outline: #1FFC33;
    border: 1px solid #1FFC33
}

.whereHouse_select_box option {
    background-color: #1E2022;
    text-align: left;
}

.w100 {
    width: 100%;
}

.ms10 {
    margin-left: 10px;
}

.lable91 {
    border: 1px solid;
    min-height: 36px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding: 0 3px;
}

.lable91input {
    outline: none;
    border-left: none;
    border-radius: 0;
    width: 55%;
}

.table_main {
    border-collapse: collapse;
    width: 100%;
    margin: 20px 0;
    height: 100%;
}

.table_main2 {
    border-collapse: collapse;
    width: 100%;
    margin: 40px 0 20px 0;
    height: 248px;
    overflow-y: scroll;
}

.table_main2tr2 {
    height: 10px;
}

.table_th_details {
    max-width: 50px;
    border: 1px solid #ddd;
    text-align: left;
    text-align: center;
    color: #FFFFFF;
}

.table_th_details input,
select {
    background: transparent;
    outline: none;
    border: none;
    color: #fff;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    text-align: center;
}

.table_th_details option {
    background: #000;
    text-align: start;
}


.table_th_Sno {
    width: 150px;
    border: 1px solid #ddd;
    text-align: left;
    text-align: center;
    color: #FFFFFF;
}

.table_th {
    border: 0.5px solid #ddd;
    text-align: left;
    /* padding: 0 15px; */
    height: 40px;
    color: #FFFFFF;
    /* display: flex; */
    position: relative;
    align-items: center;
}

.table_th div {
    margin: 0;
}

.table_th select {
    border: none !important;
}

.table_th_div1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.create_new_item {
    border: 0.5px solid #ddd;
    text-align: left;
    /* padding: 0 26px; */
    height: 40px;
    line-height: 40px;
    text-decoration-line: underline;
    cursor: pointer;
}

.searchItems_box {
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    height: 40px;
    line-height: 40px;
    /* text-decoration-line: underline; */
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    padding-left: 10px;
}

.crossIcon {
    position: relative;
    padding: 8px 0 0 8px;
    cursor: pointer;
    border: none !important;
    width: 0;
}

.table_description {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orderItemNo {
    min-width: 70px;
    text-align: left;
    padding: 0 12px;
    border: none;
}

.orderProductName {
    text-align: left;
    width: 70%;
    border: none;
}

.ViewImageIcon {
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
    text-decoration-line: underline;
    padding-bottom: 1px;
    padding-top: 1px;
    cursor: pointer;
    border: none;
}

.imageCenter {
    text-align: center;
    padding-right: 8px;
}

.viewImage_txt {
    line-height: 10px;
    border: none;
}

.paymentMethod_div {
    width: 45%;
    min-height: 130px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.paymentMethod_cod_div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 26px;
    width: 60%;
}

.payment_cod {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.payment {
    width: 38%;
}

.cod {
    width: 62%;
    box-sizing: border-box;
    background: #1E2022;
    outline: none;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
    height: 36px;
}

.status_received {
    display: flex;
    justify-content: space-between;
}

.status {
    width: 38%;
}

.recieved {
    width: 62%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-decoration-line: underline;
    color: #1FFC33;
}

.addPayment_div {
    width: 40%;
    text-align: center;
}

.addPament_btn {
    border: 1px solid #1FFC33;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 36px;
    color: #FFFFFF;
    padding: 0 32px;
    background-color: transparent;
    border-radius: 6px;
}

.subTotal_flex_col {
    width: 45%;
    min-height: 130px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.subTotal_div {
    width: 92%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
}

.shipingCharges_input {
    box-sizing: border-box;
    background-color: transparent;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    font-style: normal;
    font-weight: 300 !important;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    width: 100px;
    text-align: center;
    letter-spacing: 1px;
    outline: none;
}

.adjustment {
    box-sizing: border-box;
    border: 0.5px dashed #F3F3F3;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: #C2C2C2;
    background-color: transparent;
    text-align: center;
    outline: none;
    width: 138px;
}

.subTotal_txtRed {
    color: red;
    width: 80px;
    text-align: right;
}

.fixSize_input {
    width: 73px;
    text-align: right;
}

.totalINR_div {
    width: 92%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
}

.orders_btn_div {
    display: flex;
    justify-content: space-around;
    width: 64%;
    margin: auto;
    margin-top: 40px;
    padding-bottom: 40px;
}

/* .table,
tr,
td {
    border: none;
} */

.table_th_Sno {
    width: 62px;
}

/* ------------------------Search-------------------- */
.inputDivDealer {
    height: 100%;
    width: 65%;
}

.inputDiv {
    height: 100%;
}

.inputSeacrh {
    outline: none;
    border: none;
    height: -webkit-fill-available;
    /* width: -webkit-fill-available; */
    margin: 0;
    padding: 5px;
    background-color: transparent;
    color: #fff;
    height: 30px;
    width: 224px;
}

.inputSeacrhWhite {
    outline: none;
    border: none;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    margin: 0;
    padding: 5px;
}

.suggetion {
    position: absolute;
    background: #1E2022;
    color: #fff;
    width: -webkit-fill-available;
    overflow-y: scroll;
    height: calc(100vh - 560px);
    z-index: 1050;
    border: 1px solid;
}

.suggetionWhite {
    position: absolute;
    background: #fff;
    color: #000;
    width: -webkit-fill-available;
    overflow-y: scroll;
    max-height: 200px;
}

.foucsList {
    background-color: #fff;
    color: #000;
}

/* "cursor-pointer hover:bg-black hover:bg-opacity-10 p-2" */
.suggetionList {
    cursor: pointer;
    border-top: .5px solid #d3d3d3;
    padding: 5px;
}

.suggetionList :hover {
    background: #fff;
    opacity: 10;
    color: #000;
}


.suggetion table {
    border-spacing: 0;
    border: .5px solid #d3d3d3;
    width: 100%;

}

.suggetion tr {
    text-align: center;
    width: 100%;
}

.suggetion td {
    border: .5px solid !important;
    padding: 5px;
    font-size: 14px;
    text-align: start;
}

.outqty {
    color: red;
}

.ms_5 {
    margin-left: 10px;
    min-width: 200px;
    margin-left: 0 !important;
}


.modalMain_heading {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #FFFFFF;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0px;
}

.inputFields_div {
    width: 85%;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin: 0 20px 10px;

}

.inputFields_heading {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
}


.unbold_text {
    font-weight: 100;
}

.uploadImage_div {
    width: 85%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: auto;
    margin-top: 32px;
    align-items: center;
}

.uploadImage_btn {
    border: 1px solid #1FFC33;
    background-color: transparent;
    color: #FFFFFF;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    width: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.modalItem_Image {
    width: 76px;
    height: 38px;
    object-fit: cover;
}

.delete_div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.08em;
    color: #F93E3E;
    text-align: center;
    width: 64px;
    min-height: 36px;
    padding: 4px 0;
    cursor: pointer;
    border: 3px solid #00000045;
    border-radius: 8px;
}

.modalButton_div {
    width: 372px;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: space-around;
    margin-top: 28px;
}



.nameAndDesignation {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
    margin-top: 20px;
}

.inputField_name {
    box-sizing: border-box;
    background-color: transparent;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 36px;
    font-size: 15px;
    font-weight: 100 !important;
    min-height: 40px;
    padding: 0 8px;
    outline: none;
    width: 100%;
}

.inputField_name:focus {
    border: 1px solid #1FFC33;
}

.inputField_name option {
    background-color: #1E2022;
    text-align: left;
}

.inputField_designation {
    box-sizing: border-box;
    background-color: transparent;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 36px;
    font-size: 15px;
    font-weight: 100 !important;
    min-height: 40px;
    padding: 0 8px;
    outline: none;
    width: 100%;
}

.inputField_designation option {
    background-color: #1E2022;
    text-align: left;
}

.inputFieldName {
    width: 100%;
}

.DesignationFields_div {
    margin-left: 10px;
    width: 100%;
}

.message {
    text-align: center;
    margin: 30px 0;
    font-size: 24px;
}

.massageModal_btn {
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin: auto;
    padding-bottom: 30px;
}




/* ---------------paYMENT----------- */
.uploadRecieve_div {
    width: 85%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 1px;
    margin-top: 28px;
}

.selectBox_payment {
    width: 60%;
    background-color: #000000;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    line-height: 30px;
    font-size: 15px;
    font-weight: 400;
    height: 44px;
    padding: 0 4px;
    letter-spacing: 1px;
    outline: none;
}

.demoImage_div {
    width: 76px;
    height: 38px;
    border: 1px solid #ffffff;
    background-color: transparent;
    padding: 4px;
}

.demoImage_item {
    width: 85px;
    height: 48px;
    border: 1px solid #ffffff;
    background-color: transparent;
    padding: 4px;
}


.demoImage_bgc {
    background-color: #fff !important;
    width: 76px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputField_enterAmount {
    box-sizing: border-box;
    margin: auto;
    width: 85%;
    display: flex;
    background-color: #000000;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    height: 42px;
    margin-top: 28px;
    color: #FFFFFF;
    outline: none;
    font-size: 18px;
    font-weight: 400;
    padding: 0 8px;
    text-align: center;
}

.modalButton_cod {
    margin: 30px 0;
    text-align: center;
}

.inputField_enterId {
    box-sizing: border-box;
    margin: auto;
    width: 85%;
    display: flex;
    background-color: #000000;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    height: 42px;
    margin-top: 28px;
    color: #FFFFFF;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    padding: 0 8px;
}

.paymentPending {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #F93E3E;
    text-decoration-line: underline;
    cursor: pointer;
}


.viewImage {
    display: flex;
    width: 100%;
    margin: auto;
    height: auto;
    max-height: calc(100vh - 80px);
}

.payment_image_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
}

.payment_image {
    box-sizing: border-box;
    width: 200px;
    height: 120px;
    border: 2.2439px dashed #FFFFFF;
    padding: 10px;
}

.viewScreenshot {
    width: 200px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #1FFC33;
    text-align: left;
    margin-top: 2px;
}

.amountErr {
    color: #F93E3E;
    text-align: center;
}

.text_right {
    text-align: right;
}



.TxtLineThrough {
    -webkit-text-decoration-line: line-through;
    text-decoration-line: line-through;
    text-decoration-color: #FFFFFF;
    color: gray;
}

.TxtLine_adjustment {
    box-sizing: border-box;
    border: 0.5px dashed gray;
    font-size: 13px;
    line-height: 24px;
    background-color: transparent;
    text-decoration-line: line-through;
    text-decoration-color: #FFFFFF;
    color: gray;
}

.shipingCharges_inputTxtLine {
    background-color: transparent;
    border: 0.5px solid gray;
    border-radius: 3px;
    line-height: 24px;
    width: 100px;
    text-align: center;
    text-decoration-line: line-through;
    text-decoration-color: #FFFFFF;
    color: gray;
}

.fixSize_inputTxtLine {
    width: 73px;
    text-align: right;
    -webkit-text-decoration-line: line-through;
    text-decoration-line: line-through;
    text-decoration-color: #FFFFFF;
    color: gray;
}

.subTotal_txtRedLine {
    width: 73px;
    text-align: right;
    -webkit-text-decoration-line: line-through;
    text-decoration-line: line-through;
    text-decoration-color: red;
    color: red;
}

.acc_div_input {
    margin: 5px;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 7px 5px;
    outline: none;
    background-color: #1E2022;
    color: #fff;
    width: 100%;
    text-align: center;
}

.acc_div_input:focus {
    border: 1px solid #1FFC33;
    outline: #1FFC33;
}

.w_50 {
    width: 50%;
}

.order_txt_disable {
    line-height: 36px;
    color: gray;
}

.transfer_div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 26px;
    width: 80%;
}

.transferInput {
    width: 70%;
    box-sizing: border-box;
    background: #1E2022;
    outline: none;
    border: none;
    border-bottom: 0.5px solid #F3F3F3;
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    /* height: 36px; */
}

.transferInputDIv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2px;
}

.mobileCheckbox {
    height: 14px;
    width: 14px;
    margin-left: 6px;
    cursor: pointer;
}

.mobileCheckbox:hover {
    height: 20px;
    width: 20px;
}

.ordersScroll {
    width: 100%;
    overflow-y: scroll;
    max-height: calc(100vh - 156px);
}

.ordersScroll::-webkit-scrollbar {
    display: none;
}

.modal_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
}

.inputcolor {
    width: 50%;
    margin: 0 5px;
    height: 30px;
    outline: none;
    background: #fff;
    color: #000;
    border: 0.5px solid #000;
    border-radius: 3px;
}

.input {
    box-sizing: border-box;
    width: 50%;
    margin: 0 5px;
    height: 40px;
    outline: none;
    background: #1E2022;
    color: #fff;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    max-width: 283px;
    padding: 0 0 0 8px;
}


.modalBtn_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 360px;
    margin: 32px auto 8px;
}

.calcel_btn {
    font-size: 14px;
}

.moveToSaleModal_btn {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 24px auto 10px;
}

.addRecipt_div {
    width: 45%;
    min-height: 130px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    padding-bottom: 4px;
}

.addRecipt_btn_div {
    display: flex;
    justify-content: flex-end;
    margin: 12px 12px 0 0;
}

.addRecipt_btn {
    border: 1px solid #1FFC33;
    font-size: 14px;
    color: #FFFFFF;
    width: 140px;
    background-color: transparent;
    border-radius: 6px;
    min-height: 40px;
}

.payment_detailsDiv {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* height: 100%; */
    width: 100%;
    padding: 0 12px;
    font-size: 14px;
}

.recipt_status_red {
    color: #F93E3E;
}

.recipt_status_green {
    color: #1FFC33;
}

.recipt_status_pending {
    color: yellow;
}

.recipt_statusWidth {
    width: 210px;
}

.receipt_scroll {
    margin-top: 5px;
    max-height: 150px;
    overflow-y: scroll;
}

.receipt_scroll::-webkit-scrollbar {
    display: block !important;
    background-color: #fff;
    width: 7px;
}

.receipt_scroll::-webkit-scrollbar-thumb {
    background-color: #000;
}

.toPay_checkbox {
    width: 20px;
    height: 20px;
}

.paymentLink_input {
    background-color: #000;
    border: 1px solid #FFFFFF;
    width: 100%;
    padding: 7px 5px;
    border-radius: 5px;
    color: #FFFFFF;
}

.whatsapp_input {
    background-color: #000;
    border: 1px solid #FFFFFF;
    width: 100%;
    padding: 7px 42px;
    border-radius: 5px;
    color: #FFFFFF;
}

.copyLink {
    background: #FFFFFF;
    color: #000000;
    width: 120px;
    text-align: center;
    height: 40px;
    position: absolute;
    right: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
    padding-top: 8px;
}

.copyLinkBtn {
    background: gray;
    color: #000000;
    width: 120px;
    text-align: center;
    height: 40px;
    position: absolute;
    right: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
    padding-top: 8px;
}

.link_icon {
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    padding: 10px;
}

.copyLink svg {
    width: 20px;
    height: 20px;
    padding-bottom: 2px;
}

.copyLink a {
    text-decoration: none;
    color: #000;
    width: 10px;
    font-weight: 600;
}
.copyLinkBtn svg {
    width: 20px;
    height: 20px;
    padding-bottom: 2px;
}

.copyLinkBtn a {
    text-decoration: none;
    color: #000;
    width: 10px;
    font-weight: 600;
}

.viewDetail_btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.country_code {
    position: absolute;
    font-size: 16px;
    height: 40px;
    padding-left: 16px;
    z-index: 999;
}

.country_code_select {
    -webkit-appearance: none;
    cursor: pointer;
}

.country_code_select option {
    background: #000;
    color: #FFFFFF;
}

.country_slash {
    position: absolute;
    padding-left: 32px;
    height: 40px;
    font-size: 23px;
    z-index: 1;
}

.country_plus {
    position: absolute;
    padding-left: 5px;
    height: 40px;
    font-size: 23px;
    z-index: 1;
}

.reqIssue {
    background-color: #000;
    border: 1px solid #FFFFFF;
    width: 80%;
    padding: 7px 5px;
    border-radius: 5px;
    color: #FFFFFF;
    margin: 0px auto 40px;
    display: flex;
}

.editRequestButton {
    font-size: 15px;
}