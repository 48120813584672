.bg_white {
    background-color: #fff;
    color: #18191B;
}

.bg_dark {
    background-color: #000000;
    color: #fff;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.w_100 {
    width: 100%;
}

.m_16 {
    margin: 16px;
}

.col {
    flex: 1 0 0%;
}

.text_underline {
    text-decoration-line: underline;
}

.fs_14 {
    font-size: 14px;
}

.mb_20 {
    margin-bottom: 20px;
}

.mouse {
    cursor: pointer;
}

.marketing_main_content {
    /* font-size: 20px; */
    display: flex;
    flex-wrap: wrap;
    /* border: 1px solid; */
    border-radius: 12px;
    padding: 16px;
    background-color: #1E2022;
    min-height: 800px;
    max-height: 800px;
}

.col_6 {
    flex: 0 0 auto;
    width: 50%;
}

.bg_white_gray_Light {
    background-color: #F7F8FA;
    color: #18191B;
}


.bg_gray_dark {
    background-color: #1E2022;
    color: #fff;
}

.marketOrder {
    position: relative;
    background-color: #000000;
    color: #fff;
    border: 0.5px solid #3A3A3A !important;
    border-radius: 12px;
    padding: 20px !important;
    display: flex;
    justify-content: space-between;
}

.order_customer_name {
    font-size: 28px;
}

.mt_1 {
    margin-top: 1px;
}

.note {
    margin-top: 20px;
    justify-content: space-between;
    display: flex;
    width: 83%;
    float: right;
}

.note1 {
    width: 33.33333%;
    border-right: 1px solid #FFF;
}

.note2 {
    width: 65.666666%
}

.mb_2 {
    margin-bottom: 10px;
}

.mb_5 {
    margin-bottom: 5px;
}

.iconHw {
    width: 38px;
    height: 38px;
}

.icon_mr_40 {
    margin-right: 40px;
}

.fs_24 {
    font-size: 24px;
}

.borderNone {
    background-color: #1E2022;
    border: 1px solid #3A3A3A;
    color: #fff;
}

.p_5 {
    padding: 5px;
}

.w_50 {
    width: 50%;
}

.call_answer {
    text-align: center;
    margin-top: 30px;
    font-size: 28px;
}

.text_dark {
    color: #18191B;
}

.btnMKD {
    width: 94px;
    height: 40px;
    border-radius: 50px;
    border: none;
}

.text_white {
    color: #fff !important;
}

.br_30 {
    border-radius: 30px !important;
}

.btnMKD1 {
    width: 100px !important;
    height: 36px !important;
}

.yesOutBtn {
    border: 1px solid #1FFC33;
    background: transparent;
    color: #1FFC33;
    width: 100px;
    height: 36px;
    padding: 10px 0;
    text-align: center;
    border-radius: 30px;
    cursor: pointer;
    margin-right: 20px;
}

.yesBtn {
    border: none;
    background-color: #1FFC33;
    color: #000;
    width: 100px;
    height: 36px;
    padding: 10px 0;
    text-align: center;
    border-radius: 30px;
    cursor: pointer;
    margin-right: 20px;
}

.disableBtn {
    background: transparent !important;
    border: 1px solid #C4C4C4 !important;
    color: #C4C4C4;
    width: 100px;
    height: 36px;
    padding: 10px 0;
    text-align: center;
    border-radius: 30px;
    cursor: pointer;
}

.me_20 {
    margin-right: 20px;
}

.dangerBtn {
    border: 1px solid #F93E3E;
    background-color: transparent;
    color: #F93E3E;
    width: 155px;
    padding: 10px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

.noBtn {
    border: none;
    background-color: #F32D3C;
    color: #fff;
    width: 100px;
    height: 36px;
    padding: 10px 0;
    text-align: center;
    border-radius: 30px;
    cursor: pointer;
    /* margin-left: 20px; */
}

.float_end {
    float: right;
}

.threeDots_div_position {
    position: absolute;
    right: 0;
    top: 34px;
    z-index: 1060;
    /* padding-right: 10px; */
}

.border_05 {
    border: 0.5px solid #3A3A3A !important;
}

.fs_18 {
    font-size: 18px;
}

.borderTopDark {
    border-top: 1px solid #18191B;
}

.text_danger {
    color: #F93E3E;
}

.mt_5 {
    margin-top: 5px;
}

.mt_20 {
    margin-top: 20px;
}

.ms_10 {
    margin-left: 10px;
}

.fs_20 {
    font-size: 20px;
}

.dropdownCss {
    background-color: #18191B;
    border: 1px solid #3A3A3A;
    color: #fff;
}

.p_10_5 {
    padding: 10px 5px;
}

.mb_10 {
    margin-bottom: 10px;
}

.mt_30 {
    margin-top: 30px;
}

.ms_5 {
    margin-left: 5px;
}

.p_10 {
    padding: 10px;
}

.align_items_center {
    align-items: center;
}

.mt_10 {
    margin-top: 10px;
}

.col_11 {
    flex: 0 0 auto;
    width: 91.66666667%;
}

.col_4 {
    flex: 0 0 auto;
    width: 33.33333333%;
}

.text_center {
    text-align: center;
}

.me_5 {
    margin-right: 5px;
}

.w_50 {
    width: 50%;
}

.col_1 {
    flex: 0 0 auto;
    width: 8.33333333%;
}

.fs_10 {
    font-size: 10px;
}

.searchList {
    height: 375px;
    overflow: auto;
    border: 1px solid #3A3A3A;
    padding: 15px !important;
    /* position: absolute; */
    /* top: 52%; */
    /* width: 45%; */
}

.br_5 {
    border-radius: 5px;
}

.fs_30 {
    font-size: 30px;
}

.imgHW {
    width: 100%;
    height: 110px;
}

.text_success {
    color: #1FFC33;
}

.col_3 {
    flex: 0 0 auto;
    width: 25%;
}

.text_black {
    color: #252525 !important;
}

.ms_20 {
    margin-left: 20px;
}

.timeClr::-webkit-calendar-picker-indicator {
    background-color: white;
}

.games_dropdown_2 {
    background-color: #023950;
    color: #000;
    text-align: left;
}
.selectReact {
    background-color: #023950;
    color: #000;
    text-align: left;
    width: 100%;
}


.mt_40 {
    margin-top: 40px;
}

.marketOrder_right_col {
    flex: 0 0 auto;
    width: 50%;
    min-height: 800px;
    max-height: 800px;
    overflow-y: scroll;
}

.marketOrder_notes {
    line-height: 1rem;
    font-size: 28px;
    text-align: center;
}

.text_end {
    text-align: end;
}

.col_10 {
    flex: 0 0 auto;
    width: 83.33333333%;
}

.text_BoxHW {
    width: -webkit-fill-available;
    height: 65px;
    padding: 10px;
    background: transparent;
    outline: none;
}

.me_40 {
    margin-right: 40px;
}

.justify_content_between {
    display: flex;
    justify-content: space-between;
}

.w_20 {
    width: 20%;
}

.border_Right {
    border-right: 1px solid;
}


.w_70 {
    width: 70%;
}

.justify_content_end {
    display: flex;
    justify-content: right;
}