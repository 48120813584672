.inputDiv {
    height: 100%;
}

.inputSeacrh {
    width: 100%;
    height: 32px;
    outline: none;
    border: none;
    background: #F3F6F8;
    border-radius: 3px;
    padding-left: 4px;
    font-size: 14px;
}

.search_mob_main {
    border-radius: 3px;
}

.searchCard {
    width: 100%;
    background: #FFFFFF;
    border: 0.608031px solid #E5E5E5;
    padding: 5px;
    margin-bottom: 5px;
    display: flex;
}

.searchItem_details {
    width: 100%;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    line-height: 18px;
    margin-left: 5px;
}

.outqty {
    color: red;
}

.foucsList {
    background-color: #fff;
    color: #000;
}

.dealerCreateNew {
    color: #24ABF8;
    text-decoration: underline;
    font-size: 14px;
    margin: 8px 0;
}