.bg_white {
    background-color: #FFFFFF;
    color: #18191B;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.w_100 {
    width: 100%;
}

.col {
    flex: 1 0 0%;
}

.p_10 {
    padding: 10px;
}

.mx_10 {
    margin: 0 10px;
}

.align_items_center {
    align-items: center;
}

.col_9 {
    flex: 0 0 auto;
    width: 75%;
}

.fs_30 {
    font-size: 30px;
}

.col_3 {
    flex: 0 0 auto;
    width: 25%;
}

.bg_white {
    background-color: #fff;
    color: #18191B;
}

.border_05 {
    border: 0.5px solid #3A3A3A !important;
}

.text_center {
    text-align: center;
}

.w_96 {
    width: 96%;
}

.br_30 {
    border-radius: 30px !important;
}

.p_5 {
    padding: 5px;
}

.light_gray_clr {
    background-color: #E3E7EF;
    color: #18191B;
}

.btnHW {
    width: 33%;
    height: 40px;
    border-radius: 30px;
    border: 0px;
    cursor: pointer;
}

.bg_white_gray_Light {
    background-color: #F7F8FA;
    color: #18191B;
}

.bg_gray_dark {
    background-color: #1E2022;
    color: #fff;
}

.m_10 {
    margin: 10px;
}

.pb_15 {
    padding-bottom: 15px;
}

.border_05 {
    border: 0.5px solid #3A3A3A !important;
}

.br_5 {
    border-radius: 5px;
}

.text_warning {
    color: #FFC776;
}

.fs_50 {
    font-size: 50px;
}

.fs_18 {
    font-size: 18px;
}

.orangeclr {
    color: #F56221;
}

.text_info {
    color: #24ABF8;
}

.text_danger {
    color: #F93E3E;
}

.text_success {
    color: #1FFC33;
}

.justify_content_between {
    display: flex;
    justify-content: space-between;
}

.dealer_search_div {
    position: relative;
    width: 282px;
}
.dealer_search {
    border-radius: 50px;
    height: 49px;
    padding-left: 30px;
    padding-right: 10px;
    background-color: transparent;
    border: 1px solid white;
    color: #ffffff;
    outline-offset: 0px !important;
    outline: none;
    width: 259px;
  }

  .dealer_search_icon {
    position: absolute;
    left: 8px;
    top: 15px;
  }

  .ms_20 {
    margin-left: 20px;
  }

  .fs_20 {
    font-size: 20px;
  }
  .bg_dark {
    background-color: #000000;
    color: #fff;
  }

  .dropdown {
    width: 280px;
    height: 45px;
  }

  .ms_5 {
    margin-left: 5px;
  }

  .tableCss {
    /* text-align: center; */
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 0.5px solid #3A3A3A !important;
  }
  .mt_10 {
    margin-top: 10px;
  }
  .position_relative {
    position: relative;
  }

  .mouse {
    cursor: pointer;
  }

  .market_fs_16 {
    font-size: 14px;
  }

  .col_1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .text_underline {
    text-decoration-line: underline;
  }
  /* .text_info {
    color: #24ABF8;
  } */

  .col_2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .priority_1 {
    color: red;
    border: 1px solid;
    width: 'fit-content';
    border-radius: 25px;
    padding: 8px;
  }

  .priority_2 {
    color: #05FF00;
    border: 1px solid;
    width: 'fit-content';
    border-radius: 25px;
    padding: 8px;
  }
  
  .priority_3 {
    color: #FF9700;
    border: 1px solid;
    width: 'fit-content';
    border-radius: 25px;
    padding: 8px;
  }

  .marketingOrder_lead_div {
    position: absolute;
    top: 15px;
    right: 1%;
  }

  .marketingOrder_lead {
    cursor: pointer;
    padding-right: 16px;
  }
  .fs_26 {
    font-size: 26px;
  }

  .mt_5 {
    margin-top: 5px;
  }

  .threeDots_div_position {
    position: absolute;
    right: 0;
    top: 34px;
    z-index: 1060;
    /* padding-right: 10px; */
  }
  .mb_5 {
    margin-bottom: 5px;
  }

  .borderTopDark {
    border-top: 1px solid #18191B;
  }

  .borderTop {
    border-top: 1px solid #fff;
  }
  
.ms_2_6p {
    margin-left: 2.666%;
  }
  
  .text_start {
    text-align: start;
  }