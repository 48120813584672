.main_container {
    background-color: #fff;
    width: 100%;
    height: 100vh;
    /* padding: 10px; */
    /* margin-top: 50px; */
}

.tab_dropdown {
    border-right: 1px solid #D3D3D3;
    width: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding: 0 5px;
    text-transform: capitalize;
}

.dispatch_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #D3D3D3;
    color: #747474;
    border-radius: 5px;
    overflow: hidden;
    font-size: 10px;
    height: 30px;
    margin: 10px;
}

.dispatch_div1 {
    display: flex;
    align-items: center;
}

.dispatch_select {
    color: #000;
    font-size: 11px;
    padding: 0 5px;
}

/* .dispatch_select option {
    text-align: left;
    font-size: 11px;
} */

.date_select {
    border: none;
    outline: none;
    width: 76px;
    /* margin-right: 5px;  */
}


.date_margin_top {
    margin-top: 233px;
}

.statusCard_div {
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.statuscard1 {
    padding: 10px;
}

.statusCard {
    min-width: 60px;
    min-height: 64px;
    background: #FFFFFF;
    border: 0.407232px solid #D3D3D3;
    border-radius: 3.15007px;
    cursor: pointer;
    font-size: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* padding: 10px 0 */
}

.loadmoreIocon {
    text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
    .loadmoreIocon {
        animation: loadmoreIocon-spin infinite 1s linear;
    }
}

@keyframes loadmoreIocon-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.packed_active {
    border: 3px solid #FFC776;
}

.cancel_status {
    border: 3px solid #000;
}

.inProgress_active {
    border: 3px solid #DDE100;
}

.Dispatches_active {
    border: 3px solid #1FFC33;
}

.Pending_active {
    border: 3px solid #F32D3C;
}

.newOrder_active {
    border: 3px solid #24ABF8;
}

.orange_active {
    border: 3px solid #F56221;
}

.orders_status {
    font-weight: 600;
    font-size: 20px;
    line-height: 10px;
    position: absolute;
    right: 10px;
    top: 5px;
    margin-bottom: 10px;
}

.orders_status_filter {
    font-weight: 600;
    font-size: 20px;
}

.loadmore {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statusNew {
    color: #24ABF8;
}

.statusAll {
    color: #F56221;
    /* margin: 5px; */
}

.statusYellow {
    color: #DDE100;
    /* margin: 5px; */
}

.statusWhite {
    color: #000;
}

.statusOrange {
    color: #F56221;
}

.statusRed {
    color: #F32D3C;
}

.statusBlue {
    color: #FFC776;
}

.statusGreen {
    color: #1FFC33;
}

.statusTxt {
    font-weight: 500;
    font-size: 14px;
}

.dashboard_search {
    background: #FFFFFF;
    border: 0.5px solid #3A3A3A;
    border-radius: 50px;
    width: 100px;
    height: 25px;
    font-size: 11px;
    outline: none;
}

.dashboard_search_div {
    position: relative;
    /* width: 30%; */
}

.dashboard_search_icon {
    position: absolute;
    margin-left: -24px;
    margin-top: 3px;
    width: 15px;
    outline: none;
}

.icons_css {
    width: 30.16px;
    height: 30px;
    border: 0.5px solid #D9D9D9;
    border-radius: 16px;
    text-align: center;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    position: relative;
}

.orders_div {
    background: #FFFFFF;
    border: 0.608031px solid #E5E5E5;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 10px 0;
    position: relative;
}

.ordes_img {
    height: 100%;
    min-height: 35px;
    max-height: 60px;
}

.order_detail {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    /* width: 72%; */
}

.orderDate {
    display: flex;
    justify-content: space-between;
    min-width: 184px;
}

.orders_text {
    font-size: 22px;
    padding: 0 10px;
    /* padding: 10px 0; */
}

.order_detail h2 {
    font-size: 16px;
    color: #3A3A3A;
    margin-bottom: -0;
}

.order_detail span {
    font-size: 12px;
    /* color: #3A3A3A; */
    line-height: 18px;

}

.dispatch_select h2 {
    font-size: 18px;
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 10px;
    /* margin-top: 10px; */
}

.days_active {
    background-color: #000;
    color: #fff;
    cursor: pointer;
}

.dashboard_days_select {
    margin: 10px;
    text-align: center;
    border-radius: 5px;
    padding: 12px 0px;
    cursor: pointer;
}

.largerCheckbox {
    width: 20px;
    height: 20px;
}

.dispatch_select1 {
    color: #484A4B;
    font-size: 11px;
    /* padding: 0 15px; */
}

.dispatch_select1 h2 {
    font-size: 18px;
    height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 10px;
}

.orders {
    margin: 0 10px;
}

.noData {
    width: 100%;
    max-height: 528px;
    min-height: 528px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* -----------------Short---------- */
.short_div {
    position: absolute;
    top: 35px;
    background: #fff;
    width: 100%;
    z-index: 1900;
    text-align-last: left;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 25px 50px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    max-width: 300px;
    right: 0;
}

.short_div h3 {
    margin-bottom: 15px;
}

.short_div h6 {
    margin: 2px 0;
    text-align: center;
    padding: 5px;
    cursor: pointer;
}

.receipt_card_heading {
    color: #3A3A3A;
}

.receipt_Id {
    font-size: 13px;
    font-weight: bold;
    color:#3A3A3A;
}

.receipt_details {
    font-size: 13px;
    color: #3A3A3A;
    font-weight: 400;
}

.receiptStatus_red {
    color:#F32D3C;
    font-weight: 500;
    font-size: 15px;
}

.receiptStatus_green {
    color: #1FFC33;
    font-weight: 500;
    font-size: 15px;
}

.receiptStatus_yellow {
    color: #DACD10;
    font-weight: 500;
    font-size: 15px;
}

.verify_time {
    color: #1FFC33;
    font-weight: 600;
    font-size: 11px;
    line-height: 26px;
    margin-right: 10px;
} 

.modal_backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1090;
    height: 100%;
}

.modal_content {
    width: 599px;
    overflow-y: scroll;
    min-height: 200px;
    background-color: #fff;
    color: #000;
    padding: 25px;
}

.modal_content::-webkit-scrollbar {
    display: none;
}

.packingImage_content {
    width: 90%;
    overflow-y: scroll;
    min-height: 150px;
    background-color: #1E2022;
    color: #FFFFFF;
    padding: 10px 8px 24px 8px;
    margin-left: 66px;
}

.packingImage_content::-webkit-scrollbar {
    display: none;
}


.modal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal_header h3 {
    margin: 0;
}

.modal_close_button {
    font-size: 1.4rem;
    font-weight: 700;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    background: transparent;
}

.main_search_div {
    position: relative;
}

.inputSearch {
    width: 100%;
    padding: 10px 10px 10px 30px;
    outline: none;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    /* position: relative;  */
}

.search_icon {
    position: absolute;
    margin-left: 10px;
    margin-top: 12px;
    width: 15px;
    height: 15px;
}

.main_search_div h3 {
    text-align: center;
    margin-top: 0;
}

.main_search_div p {
    text-align: center;
    color: darkgray;
}

.locations {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
}

.locations h4 {
    margin: 0;
}

/* @media only screen and (max-width: 991px) {
    .modal_content {
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .modal_content {
        width: 90%;
    }
}
*/

.recipt_tableDiv {
    width: 200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    float: right;
}


.recipt_table_cell {
    min-width: 50%;
    text-align: center;
    border: 1px solid #000000;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 18px;
}

.reciptContentDiv {
    padding: 0;
}

.method {
    font-size: 15px;
    font-weight: 600;
}

.methodSelect {
    width: 100%;
    background: #F3F6F8;
    height: 36px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: left;
    font-size: 16px;
    color: #000;
}

.methodSelect option {
    background-color: #ffffff;
}

.amount_input {
    width: 100%;
    background: #F3F6F8;
    height: 36px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size:18px;
    color: #000000;
}

.submethod_txt {
    font-size: 15px;
    font-weight: 600;
}

.reciptMethod_select {
    box-sizing: border-box;
    height: 36px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    font-size: 16px;
    text-align: left;
    color: #000000;
    background: #F3F6F8;
    width: 100%;
    margin: 0px 0 10px 1px;
}
.subMethod_select {
    box-sizing: border-box;
    height: 36px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    font-size: 16px;
    /* padding: 0 6px; */
    text-align: left;
    color: #000000;
    background: #F3F6F8;
    width: 100%;
    margin: 0;
}

.reciptMethod_select option {
    background-color: #FFFFFF;
    color: #000000 !important;
}

.reciptImage_div {
    display: flex;
    justify-content: center;
    /* align-items: flex-start; */
    margin-top: 12px;
    position: relative;   
}

.reciptImage {
    height: 100%;
    max-height: 224px;
    min-height: 224px;
}

.Image_div {
    border: 2.685px dashed #FFFFFF;
    padding: 12px;
    max-width: 350px;
    min-width: 350px;
    max-height: 250px;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reciptImage_delete {
    right: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #F93E3E;
    /* border: 3px solid #00000038; */
    border-radius: 6px;
    padding: 10px 4px 0px 4px;
    line-height: 20px;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
}

.viewRecipt {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #1FFC33;
    margin-bottom: 0;
    padding-left: 84px;
}

.dragImage_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    padding: 16px 0 0px 0;
    /* margin: 12px 0; */
}

.recipt_inputField_ref {
    width: 100%;
    background: #F3F6F8;
    height: 36px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 14px;
    color: #000;
    letter-spacing: 3px;
    margin: 16px 3px 12px 3px;
    outline: none;
    font-weight: 600;
}

.recipt_inputField {
    width: 100%;
    background: #F3F6F8;
    height: 36px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 14px;
    letter-spacing: 3px;
    margin: 16px 3px 12px 3px;
    color: #000;
    outline: none;
    font-weight: 600;
}

.recipt_transactionAmount {
    width: 100%;
    background: #F3F6F8;
    height: 36px;
    border: 0.5px solid #F3F3F3;
    border-radius: 3px;
    text-align: center;
    font-size: 14px;
    letter-spacing: 3px;
    margin: 6px 1px 12px 1px;
    color: #000;
    outline: none;
    font-weight: 600;
}

.recipt_btn {
    display: flex;
    justify-content: space-between;
    max-width: 380px;
    margin: auto;
}

.orders_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: 13px;
}

.order_btn {
    border: 1px solid #000000;
    outline: none;
    background: #fff;
    border-radius: 3px;
    width: 50%;
    height: 36px;
    margin: 0 10px;
}

.order_btn1 {
    border: 1px solid #000000;
    outline: none;
    background: #000;
    border-radius: 3px;
    color: #ffff;
    width: 50%;
    height: 36px;
    margin: 0 10px;
}

.order_btn1_disable {
    cursor: not-allowed;
    pointer-events: none;
    color: #c0c0c0;
    background-color: #ffffff;
    border: 1px solid #c0c0c0;
    outline: none;
    border-radius: 3px;
    width: 50%;
    height: 36px;
    margin: 0 10px;
}

.reciptHeading {
    font-size: 20px;
} 

.subMethod_div {
    /* margin-right: 0.25rem; */
    width: 100%;
    min-width: 49%;
    margin: 0 6px 12px 0;
}

.cancel_receipt_btn {
    font-size: 16px;
    height: 36px;
    line-height: 16px;
}

.warningInput {
    width: 100%;
    margin: 16px 0 4px 0;
}

.message {
    text-align: center;
    font-weight: 600;
}

.reciptHeader_div {
    margin-bottom: 10px;
}

.warnigModal_btn {
    display: flex;
}

.modal_backdrop_overlay {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: right;
    align-items: center;
    margin: auto;
    z-index: 1090;
    min-height: 100vh;
}

.modal_content_overlay {
    overflow-y: scroll;
    background-color: #FFFFFF;
    width: 100px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    border-radius: 3px;
    margin-right: 24px;
    margin-bottom: 12px;
    border: 1px solid #000000a8;
    /* z-index: 999; */
}

.modal_content_overlay::-webkit-scrollbar {
    display: none;
}

.modal_content_overlay span {
    line-height: 24px;
    padding-left: 6px;
    font-weight: 400;
    font-size: 14px;
    margin: 1px 2px;
    border-radius: 4px;
}

.modal_content_overlay span:hover {
    background-color: #1E2022;
    color: #FFFFFF;
    font-weight: 600;
}


@media only screen and (max-width: 599px) {
    .modal_content {
        width: 100%;
        padding: 10px;
        position: absolute;
        bottom: 0;
    }
}

@media screen and (min-width:390px) {
    .dispatch_div {
        font-size: 12px;
        /* padding:10px 5px; */
    }

    .tab_dropdown {
        width: 80px;
    }

    .date_select {
        width: 87px;
    }

    .dashboard_search {
        width: 150px;
    }
}

@media screen and (max-width:390px) {
    .statusTxt {
        font-size: 10px;
    }
}