.main_div_dark {
    background-color: #000000;
    width: 100%;
    padding: 16px;
    margin: auto;
}

.main_container {
    background: #fff;
    box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    padding: 0 50px;
    min-height: calc(100vh - 100px);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}


.page {
    background: #fff;
    display: block;
    box-shadow: var(--pageShadow);
    width: 21cm;
    /* height: 29cm; */
    overflow: hidden;
    position: relative;
    /* border-radius: 19.9728px; */
    z-index: 1060;
    padding: 0 20px;
    width: 1005;
    padding-top: 15px;
    margin: auto;
}

.printIcons {
    display: flex;
    display: flex;
    justify-content: center;
    /* border: 1px solid; */
    width: fit-content;
    padding: 20px;
    margin: auto;
}

.name {
    font-size: 15px;
    margin: 0;
}

.print_page {
    /* background: var(--white); */
    display: block;
    position: relative;
    /* box-shadow: var(--pageShadow); */
    width: 21cm;
    overflow: hidden;
    /* border-radius: 19.9728px; */
}

/* .section {
    padding: 20px 10px;
} */
.sysmsg {
    text-align: center;
    font-size: 12px;
}

.sysmsg div {
    text-decoration: underline;
}

.container {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    max-width: 599px;
    margin: auto;
    padding: 20px 20px;
    width: 100%;
}

.imgLogMainDiv {
    position: relative;
}

.imgLogo {
    position: absolute;
    left: 0;
    top: -5px;
    text-align: -webkit-center;
    font-size: 10px;
    line-height: 0;
}

.maindiv {
    margin-top: 30px;
}

.heading {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #030303;
}

.heading_div {
    font-weight: 500;
    font-size: 9.58549px;
    text-transform: capitalize;
    color: #000000;
    margin: 0px;
}

.heightItems {
    height: 660px !important;
    border-collapse: collapse;
    margin: auto;
    min-width: 10%;
    width: 100%;
}

.heading_div2 {
    font-weight: 500;
    font-size: 9.58549px;
    text-transform: capitalize;
    color: #000000;
    margin: 0px;
    height: 10px;
}

.heading_div1 {
    font-weight: 500;
    font-size: 10px;
    text-transform: capitalize;
    color: #000000;
    margin: 0px;
    border: 1px solid;
    border-bottom: 0 !important;
    height: 10px;
}

.main_div {
    border: 1px solid;
    padding: 3px 0 0 3px;
}

.Invoice {
    border-collapse: collapse;
    margin: auto;
    min-width: 10%;
    width: 100%;
}

.logo {
    width: 50px;
}

.td {
    border: solid 1px black;
    text-align: center;
    border-collapse: collapse;
}

.bold~tr td {
    border: solid 1px #030303;

}

.w20 {
    max-width: 20px;
}

.w13cm {
    width: 12cm !important;
}

.text {
    /* font-weight: 300; */
    font-size: 10px;
    /* text-transform: capitalize; */
    color: #030303;
}

.text2 {
    /* font-weight: 300; */
    font-size: 10px;
    text-transform: capitalize;
    /* color: #030303; */
    text-align: center;
}

.text1 {
    /* font-weight: 300; */
    font-size: 10px;
    /* text-transform: capitalize; */
    color: #030303;
    text-align: start;
    border-top: 0 !important;
    border: 1px solid;
}

.scaner {
    text-align: center;
    padding: 5px;
}

.payment_pending {
    font-weight: 500;
    font-size: 70px;
    line-height: 70px;
    padding-left: 5rem;
    color: #000000;
    position: absolute;
    opacity: 0.2;
    transform: rotate(-26.56deg);
}

.inputfield {
    margin: 10px;
    outline: none;
    width: 88%;
}

.table_body {
    border: none;

}

.table_body td {
    text-align: center !important;
}

.border {
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    border-bottom: none;
}

.trthL {
    text-align: left;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
}

.trthR {
    text-align: right;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
}

.float_left {
    float: right;
    padding-right: 3px;
}

.float_div {
    float: right;
    font-weight: 300;
    font-size: 10px;
    text-transform: capitalize;
    color: #030303;
    text-align: left;
    width: 63%;
    margin-top: -100px;
}

.img_div {
    padding-left: 10px;
    padding-top: 20px;
}

.displeayflex {
    display: flex;
    padding-top: 18%;
}

.last_divsecond {
    width: 60%;
    float: right;
    text-align: end;
    font-size: 10px;
    margin-top: 10px;
    padding-right: 3px;
    border-top: 0.5px solid;
    border-left: 0.5px solid;
    border-collapse: collapse;
    padding: 2px 5px;
    line-height: 20px;
}


.logo_div {
    font-weight: 300;
    font-size: 10px;
    text-transform: capitalize;
    color: #030303;
    text-align: left;
}

.logotext {
    width: 76px;
    font-size: 10px;
    font-weight: 300;
    color: #030303;
    text-transform: capitalize;
}

.last_div {
    float: right;
    border-top: 0.5px solid;
    border-left: 0.5px solid;
    border-collapse: collapse;
    margin-top: 10px;
    width: 95%;
    text-align: end;
    padding-right: 3px;
}

.icons_div {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    width: 80%;
    margin: auto
}

.icons {
    width: 36.4px;
    height: 35.59px;
    border: 0.861765px solid #D3D3D3;
    border-radius: 8.61765px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.display_flex {
    display: flex;
    justify-content: space-between;
}


.details_div {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.order_detail_heading_div {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 500;
    font-size: 9.58549px;
    line-height: 16px;
    text-transform: capitalize;
    color: #000000;
}

.order_detail_div {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 300;
    font-size: 9.58549px;
    line-height: 16px;
    color: #000000;
    padding-right: 3px;
    margin-right: auto;
    width: 90%;
}

.qr_div {
    width: 90%;
    margin: auto;
}

.text_last {
    font-weight: 300;
    font-size: 10px;
    text-transform: capitalize;
    color: #030303;
    text-align: center;
    padding-top: 10px;
}

.icontext {
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    text-align: center;
    margin: 5px;
}


.padding {
    padding-top: 150px;
}

/* @media screen and (max-width:375px) {
    .section {
        padding: 20px 0px;
    }

    .container {
        padding: 20px 0px;
        width: 100%;
    }

    .maindiv {
        padding: 20px 10px;
    }

    .Invoice {
        width: 90%;
    }

    .padding {
        padding-top: 50px;
    }
}

@media screen and (max-width:320px) {
    .maindiv {
        padding: 20px 0px;
    }
} */