.AppHome {
    background-color: #000;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #fff;
}

.AppHome h1 {
    color: #f93e3e;
    font-size: 50px;
    font-weight: bold;
}

.AppHome h2 {
    margin-bottom: 0;
    max-width: 80%;
    text-align: center;
}

.AppHome p {
    max-width: 80%;
    font-size: 20px;
    line-height: 30px;
}

.ms50 {
    margin-left: 50px;
}

.AppHome div {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; */
    max-width: 80%;
}

.danger {
    height: 300px;
    color: #f93e3e !important;
}

/* ------------------------------ Modal CSS ------------------------- */
.custom_modal {
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    padding-top: 80px;
}

/* Modal content */
.modal_content {
    background-color: #1E2022;
    color: #fff;
    width: 50%;
    max-width: 850px;
    min-height: 500px;
    margin: auto;
    padding: 10px;
    border: 1px solid black;
    border-radius: 10px;
}

.modal_content_white {
    background-color: #F7F8FA;
    color: #1E2022;
    width: 50%;
    max-width: 850px;
    height: 480px;
    margin: auto;
    padding: 10px;
    border: 1px solid black;
    border-radius: 10px;
}

/* Close button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 25px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.text_center {
    text-align: center;
}

.center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.center h1 {
    margin-bottom: 30px;
}

/* ----------------------------- End Modal CSS ------------------------- */

.loginInput {
    padding: 10px;
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    width: 100%;
    position: relative;
    border-radius: 5px;
}

.loginEye {
    position: absolute;
    right: 0;
    margin-top: 10px;
    color: #fff;
    cursor: pointer;
}

.err {
    color: red;
    font-size: 12px;
    margin-bottom: 0;
}

.px10 {
    font-size: 14px;
    padding: 9px 10px;
}

@media screen and (max-width: 768px) {

    /* .ms50 {
        margin-left: 10px;
    } */
    .btnW {
        width: 'fit-content';
        padding: 10px;
    }

    .danger {
        height: 'fit-content';
    }

    .AppHome h1 {
        font-size: 32px;
    }

    .danger {
        height: 100px;
    }

    .AppHome h2 {
        font-size: 19px;
    }

    .AppHome p {
        font-size: 14px;
        margin: 4px 0 8px 0;
    }

    .center {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 94%;
    }
}