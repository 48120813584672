.kyc_main_container_div {
  width: 100%;
  background-color: transparent;
  color: #ffffff;
  padding: 16px;
}

.d_flex_align_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.kyc_main_div {
  padding: 16px;
  width: 100%;
  color: #fff;
  height: calc(100vh - 116px);
}

.kyc_sub_main_div {
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  max-width: 1920px;
  margin: auto !important;
  background-color: #1E2022;
}

.kyc_main_heading {
  font-size: 30px;
  text-align: center;
  margin: 16px;
}

.socialMedia_icons {
  position: absolute;
  /* display: inline; */
  border: 1px solid;
  border-right: none;
  text-decoration: none;
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kyc_scroll_div {
  width: 100%;
  overflow-y: scroll;
  max-height: 780px;
}

.kyc_scroll_div::-webkit-scrollbar {
  display: none;
}

.text_end {
  text-align: end;
}

.mlrb {
  margin: 0px 16px 24px 16px;
}

.store_Image_div {
  width: 100%;
  height: 100%;
}

.profileAndStore {
  display: flex;
  justify-content: space-between;
  color: #FFFFFF;
  margin-top: 10px;
}

.profile_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  width: 80%;
  height: 80%;
  font-size: 14px;
}

.profileImage {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2.2439px dashed #FFFFFF;
  height: 300px;
  margin-right: 10px;
}

.storeImage {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2.2439px dashed #FFFFFF;
  width: 50%;
  height: 300px;
}

.d_none {
  display: none;
}

.store_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  width: 90%;
  height: 80%;
  font-size: 28px;
}

.Catalogue {
  margin-top: 20px;
  margin-bottom: 8px;

}

.row_mt_20 {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.bg_Transparent_w_85_border_p_10 {
  box-sizing: border-box;
  background-color: #1E2022 !important;
  color: #FFFFFF !important;
  width: 84%;
  border: 1px Solid #D3D3D3;
  outline: none;
  padding: 10px;
  text-align: left;
}

.bg_gray_dark {
  background-color: #1E2022;
  color: #fff;
}

.position_relative {
  position: relative;
}

.bg_Transparent_w_75_border_p_10_ml_30 {
  background-color: #1E2022 !important;
  border: 0.1px Solid #D3D3D3;
  width: 75%;
  padding: 8px 10px;
  margin-left: 40px;
  color: #ffffff;
  outline: none;
}

.row_br_5_bg_dark_p_10 {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  background-color: #18191B;
  color: #fff;
  padding: 10px;
}

.tw_center {
  text-align: -webkit-center;
}

.text_right {
  text-align: right;
}

.productBtn {
  /* min-width: 110px; */
  background-color: transparent;
  border: 1.5px solid #1FFC33;
  border-radius: 5px;
  padding: 7px;
  color: #1FFC33;
  font-size: 14px;
  cursor: pointer;
  margin: 2px 4px;
}

.product_pending {
  /* min-width: 110px; */
  background-color: transparent;
  border: 1.5px solid yellow;
  border-radius: 5px;
  padding: 7px;
  color: yellow;
  font-size: 14px;
  cursor: pointer;
  margin: 2px 4px;
}

.productDisableBtn {
  /* min-width: 110px; */
  background-color: transparent;
  border: 1.5px solid white;
  border-radius: 5px;
  padding: 7px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  margin: 2px 4px;
}

.bg_Transparent_Kyc_Mrgn {
  background-color: transparent;
  margin-left: 25px;
  margin-right: 25px;
}

.greenClr_fs_12 {
  color: #1FFC33;
  font-size: 12px;
}

.grayClr_fs_12 {
  color: #9A9A9A;
  font-size: 12px;
}

.fs_20_mt_10 {
  font-size: 17px;
  margin: 12px 0 6px 0;
}

.mt_0 {
  margin-top: 0px;
}

.margin {
  margin-top: 10px;
  margin-bottom: 8px;
}

.pl_5 {
  padding-left: 5px;
}

.bg_Transparent_w_98_border_p_maplink {
  box-sizing: border-box;
  background-color: transparent;
  width: 100%;
  border: 1.5px solid white;
  padding: 15px 10px;
  color: #ffffff;
}

.fs_14 {
  font-size: 14px;
}

.align_items_center {
  align-items: center;
}

.bg_Transparent_w_98_border_p_10 {
  background-color: transparent;
  width: -webkit-fill-available;
  padding: 10px;
  border: 1.5px solid white;
  color: #ffffff;
  outline: none;
}

.text_end_text_danger_fs_12 {
  text-align: end;
  color: #ED4F4F;
  font-size: 12px;
}

.kyc_sub_heading {
  text-align: center;
  margin-top: 20px;
  font-size: 30px;
}

.float_end {
  margin-top: 15px;
  text-align: right;
}

.row_fs_Kyc20_align_items_center {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  /* align-items: center; */
}

.col_10 {
  flex: 0 0 auto;
  width: 75.33333333%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.demo {
  padding-left: 5px;
  font-size: 30px;

}

.col6 {
  flex: 0 0 auto;
  width: 50%;
}

.order {
  flex: 0 0 auto;
  width: 41.66666667%;
  margin-bottom: 8px;
}

.col12 {
  flex: 0 0 auto;
  width: 100%;
}

.col3 {

  flex: 0 0 auto;
  width: 25%;

}

.col9 {
  flex: 0 0 auto;
  width: 75%;
}

.kyc_input {
  box-sizing: border-box;
  background-color: transparent;
  width: 100%;
  border: 1.5px solid white;
  padding: 10px;
  color: #ffffff;
  outline: none;
}

.kyc_input_select {
  background-color: transparent;
  width: 82%;
  border: 1.5px solid white;
  padding: 10px;
  color: #ffffff;
  outline: none;
  text-align: left;
}

.kyc_toggle_btn {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  border: 1px solid;
  border-radius: 25px;
  margin-top: 56px;
  padding: 1px;
  font-size: 16px;
}

.kyc_toggle_btn_profile_img {
  flex: 0 0 auto;
  display: flex;
  text-align: center;
  padding: 1px;
  width: 60px;
  margin: auto;
  border: 1px solid;
  border-radius: 25px;
  justify-content: center;
  height: 28px;
  margin-top: 10px;
}

.mr_40 {
  margin-right: 40px;
}

.radio_Btn {
  width: 25px;
  height: 25px;
}

.toggle_btn {
  margin-left: 12px;
  margin-top: 37px;
  padding: 1px;
  width: 60px;
  border: 1px solid;
  border-radius: 25px;
  justify-content: center;
  height: 28px;
}

.toggle_btn_active {
  background: #1FFC33;
  width: 50%;
  border-radius: 25px;
}

.toggle_btn_deactive {
  background: #ED4F4F;
  width: 50%;
  border-radius: 25px;
}

.d_none {
  display: none;
}

.justify_space_between {
  justify-content: space-between;
}

.min_height_200 {
  min-height: 200px;
}

.kyc_w_50_mouse {
  cursor: pointer;
  width: 50%;
}

.kyc_dFlex_justifyCenter {
  display: flex;
  justify-content: center;
}

.kyc_col_2_text_center {
  text-align: center;
  flex: 0 0 auto;
  width: 16.66666667%;
  padding-top: 44px;
}

.productName_center {
  text-align: center;
}

.kyc_upload_aadhaarImg_heading {
  background-color: #282c34;
  height: 200px;
  max-width: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  min-height: 200px;
  border: 2.2439px dashed #FFFFFF;
  cursor: pointer;
}

.kyc_upload_aadhaar_image {
  border: 2.2439px dashed #FFFFFF;
  width: 130px;
  height: 180px;
  max-height: 180px;
  margin-top: 30px;
  padding: 10px 25px 10px 25px;
}

.kyc_row_fs_Kyc20 {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  margin-bottom: 20px;
}

.kyc_col_2_item_Center {
  align-items: center;
  text-align: center;
  flex: 0 0 auto;
  width: 16.66666667%;
}

.kyc_radioBtn_div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
}

.kyc_radioBtn_col_3 {
  flex: 0 0 auto;
  width: 25%;
  align-items: center;
  display: flex;
}

.kyc_mouse_radio_Btn {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.kyc_radioBtn_col_4 {
  flex: 0 0 auto;
  width: 33.33333333%;
  align-items: center;
  display: flex;
}

.kyc_col_9 {
  flex: 0 0 auto;
  width: 75%;
  align-items: center;
  display: flex;
}

.surface {
  margin-top: 19px;
}

.text_end {
  text-align: end;
}

.darkBtn {
  border: 1px solid #BABABA;
  background-color: transparent;
  color: #BABABA;
  width: 155px;
  padding: 10px 0;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.successBtn {
  width: 155px;
  background-color: transparent;
  border: 1px solid #1FFC33;
  border-radius: 5px;
  padding: 10px 0;
  color: #1FFC33;
  font-size: 13px;
  cursor: pointer;
}

.greenBtn {
  border: 1px solid #1FFC33;
  background-color: #1FFC33;
  width: 155px;
  padding: 10px 0;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.dangerBtn {
  border: 1px solid #F93E3E;
  background-color: transparent;
  color: #F93E3E;
  width: 155px;
  padding: 10px 0;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.uploadLabel {
  border: 1px solid #1FFC33;
  width: 155px;
  padding: 10px 2px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.enterUrlLabel {
  border: 1px solid #F3F3F3;
  width: 200px;
  padding: 13px;
  border-radius: 5px;
  cursor: pointer;
}

.trashBin {
  background: #1E2022;
  box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
  text-align: center;
  padding: 3px;
  border-radius: 6px;
  color: #F93E3E;
  cursor: pointer;
}

.caretDown {
  background: #1E2022;
  box-shadow: 0px 0px 5.21429px 2.60714px rgba(0, 0, 0, 0.16);
  text-align: center;
  padding: 3px;
  margin-left: 20px;
  border-radius: 10px;
  color: #D0D0D0;
  cursor: pointer;
  cursor: pointer;
}

.col5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.margin_LR {
  margin: 0 10px;
}

.dflex_space_between {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.map_div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.map_seacrh_div {
  width: 100%;
  position: relative;
}

.seacrh_inputField {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  height: 40px;
  background-color: transparent;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  padding: 0 4px;
  font-size: 15px;
  outline: none;
}

.search_Icon {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-right: 1%;
  cursor: pointer;
}

.google_map {
  border: 0;
  width: 100%;
  height: 320px;
  margin-top: 10px;
}

.accept_modal {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  padding-top: 360px;
}

.accept_modal_content {
  width: 90%;
  max-width: 500px;
  background: #1E2022;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: auto;
  padding-top: 2px;
  color: #FFFFFF;
}

.close_btn {
  color: #aaaaaa;
  float: right;
  font-size: 25px;
  font-weight: bold;
  line-height: 1.5rem;
  padding-right: 6px;
}

.message {
  text-align: center;
  margin: 30px 0;
  font-size: 24px;
}

.massageModal_btn {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin: auto;
  padding-bottom: 30px;
}

.goBack {
  cursor: pointer;
  border-bottom: 1px solid #FFFFFF;
  /* text-decoration-line:  underline; */
}

.searchPlace_div {
  flex: 0 0 auto;
  width: 58.3%;
}

.map_heading_div {
  display: flex;
  /* justify-content: space-around; */
  flex-direction: column;
  width: 100%;
}

.map_txt {
  width: 20%;
  padding-bottom: 6px;
}

.OfficeUse_col {
  flex: 0 0 auto;
  width: 33.33%;
}

.leadModal_btn {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin: auto;
  padding-bottom: 30px;
  margin-top: 70px;
}

.rejectionList_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #FFFFFF;
  padding: 6px 0 4px 0;
}

.rejectionList {
  width: 17%;
}

.rejectionList_text {
  width: 50%;
  text-align: center;
}

.rejectionList_checkbox {
  width: 40px;
}

.rejectCheckbox {
  cursor: pointer;
}

.rejectListModal_btn {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin: 16px auto;
}

.input_field_alert {
  color: red;
}

.profileImage_alert {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2.2439px dashed red;
  height: 300px;
  margin-right: 10px;
}

.profile_bg_alert {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  width: 80%;
  height: 80%;
  font-size: 14px;
  color: red;
}

.storeImage_alert {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2.2439px dashed red;
  width: 50%;
  height: 300px;
}

.store_bg_alert {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  width: 90%;
  height: 80%;
  font-size: 28px;
  color: red;
}

.rowOffice {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.rowOffice .col3 {
  width: 33.333333%;
  margin-bottom: 20px;
}

.dfac {
  display: flex;
  align-items: center;
}

.dfac label {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.profile_Image_div {
  width: 100%;
  height: 100%;
}

.plus_product {
  font-weight: bolder;
  font-size: 14px;
  width: 20px;
}

@media (max-width:2560px) {
  .profileImage {
    width: 280px;
    height: 320px;
  }

  .storeImage {
    width: 800px;
    height: 320px;
  }

  
}

@media (max-width: 1500px) {
  .profileImage {
    width: 180px;
    height: 220px;
  }

  .storeImage {
    width: 600px;
    height: 250px;
  }

  .profileImage_alert {
    width: 180px;
    height: 220px;
  }

  .storeImage_alert {
    width: 600px;
    height: 250px;
  }
}

@media (max-width: 1200px) {
  .profileImage {
    width: 180px;
    height: 200px;
  }

  .storeImage {
    width: 600px;
    height: 200px;
  }

  .profileImage_alert {
    width: 180px;
    height: 200px;
  }

  .storeImage_alert {
    width: 600px;
    height: 200px;
  }
}

@media only screen and (max-width: 1595px) {
  .Kyc_Mrgn {
    margin-left: 15px;
    margin-right: 15px;
  }

  .col_10 {
    flex: 0 0 auto;
    width: 75.33333333%;
  }


}

@media only screen and (max-width: 1350px) {
  .row_fs_Kyc20_align_items_center {
    font-size: 18px;
  }

  .profileImgHW {
    width: 200px;
    height: 200px;
  }

  .storeImgHW {
    width: 600px;
    height: 300px;
  }

  .qr_HW {
    width: 145px;
    height: 145px;
  }

  .storeImgHW {
    width: 635px;
    height: 250px;
  }

  .Kyc_Mrgn {
    margin-left: 15px;
    margin-right: 15px;
  }

  .productBtn {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .productDisableBtn {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1220px) {
  .Kyc_Mrgn {
    margin-left: 10px;
    margin-right: 10px;
  }

  /* .productBtn {
    width: 90px;
    background-color: transparent;
    border: 1.5px solid #1FFC33;
    border-radius: 5px;
    padding: 7px 0;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  } */

  /* .productDisableBtn {
    border: 1px solid #BABABA;
    background-color: transparent;
    color: #BABABA;
    width: 90px;
    padding: 7px 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  } */
}

@media only screen and (max-width: 1140px) {
  .storeImgHW {
    width: 535px;
    height: 250px;
  }

  .Kyc_Mrgn {
    margin-left: 7px;
    margin-right: 7px;
  }

  .col_10 {
    flex: 0 0 auto;
    width: 70.33333333%;
  }

  .qr_HW {
    width: 135px;
    height: 135px;
  }

  .productBtn {
    /* width: 85px; */
    font-size: 12px;
    cursor: pointer;
  }

  .productDisableBtn {
    font-size: 12px;
  }
}

/* -------------------------------------------------------------New-KYC------------------------------------------------------------------------ */

.revertBack_btn {
  font-size: 16px;
  margin: 0 10px;
}

.kyc_btn_div {
  padding-top: 10px;
}

.billingDetails_heading {
  font-weight: 400;
  font-size: 24px;
  margin: 16px 0;
}

.profileImage_div {
  width: 280px;
}

.billingDetails_div {
  display: flex;
}

.accountSetting {
  background-color: rgba(27, 28, 29, 1);
  padding: 0 32px;
}


.mapHeading_txt {
  width: 100%;
  padding-bottom: 6px;
}

.accountSetting_div {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
}

.accountSetting_left {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.accountSetting_right {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.Catalogue_ml {
  margin-bottom: 26px;
  margin-left: 20%;
}

.kyc_div_height {
  width: 100%;
  overflow-y: scroll;
  max-height: calc(100vh - 150px);
  min-height: calc(100vh - 150px);
}

.kyc_div_height::-webkit-scrollbar {
  display: none;
}