.main_div {
    padding: 5px;
    min-height: calc(100vh - 104px);
    overflow-y: scroll;
}

.search_item_main_div {
    margin-top: 0.5rem;
}

.search_item_main_div::-webkit-scrollbar {
    display: none;
}

.search_item_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 86px;
    background: #FFFFFF;
    border: 0.407232px solid #D3D3D3;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    padding: 0px 6px 0px 4px;
}

.mt_1rem {
    margin-top: 0.5rem;
}

.stock_status_div {
    min-width: 54px;
    max-width: 54px;
    position: relative;
    display: flex;
    align-items: flex-end;
    text-align: center;
}

.stock_status {
    position: absolute;
    font-weight: 900;
    font-size: 12px;
    line-height: 10px;
    width: 100%;
    background: linear-gradient(180.94deg, rgba(255, 255, 255, 0) 27.25%, #000000 99.19%);
    border-radius: 3px;
}

.In_stock {
    color: #FFFFFF;
    padding-bottom: 4px;
}

.out_stock {
    color: #DA1515;
    padding-bottom: 2px;
}

.search_item_image {
    width: 100%;
    border-radius: 3px;
}


.search_item_availabel_Qty {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    display: flex;
    align-items: center;
    width: 70px;
    flex-direction: column;
    padding-bottom: 0px;
}

.item_No_txt {
    width: 100%;
    display: flex;
    justify-content: left;

}

.search_item_wallpaperNo {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    width: 64px;
    width: 110px;
}

.search_item_buyNowBtn {
    /* border-radius: 4px;
    border: 1px solid;
    height: 24px;
    background-color: #FFFFFF; */

    background: #fff;
    border: 1px solid #1010104d;
    border-radius: 4px;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    line-height: 24px;
    padding: 0 6px;
}

.search_item_availabel_div {
    width: 100%;
}

.itemTxt_div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 4px;
}

.item_txt {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    width: 32%;
}

.item_txt_name {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    width: 68%;
}

.cartBtn_div {
    min-width: 84px;
}

/* ---------------------------------------------------------------- */



.availability_card_div {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    min-height: 140px;
    margin: 1rem 0;
    padding: 0 10px 10px 10px;
}

.availability_heading {
    padding: 10px 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #3A3A3A;
    margin-top: 0;
    margin-bottom: 0;
}

.searchBox_main_div {
    padding-top: 4px;
    position: relative;
}

.searchBox_div {
    display: flex;
}

.qr_image {
    position: absolute;
    right: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;

    cursor: pointer;
    border: 0;
    outline: none;
}

.searchBox_text {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    text-transform: capitalize;
    color: #3A3A3A;
    margin-bottom: 10px;
}

.search_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    background: #000000;
    border-radius: 3px;
    width: 66px;
    height: 50px;
    border: 0;
    outline: none;
}

.searchBox_input {
    width: 100%;
    height: 50px;
    background: #F3F6F8;
    border-radius: 3px;
    outline: none;
    border: none;
    padding: 10px;
    padding-right: 60px;
    font-size: 16px;
    color: #000;
}

.dc {
    display: contents;
}